import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'my-rep-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent implements OnInit {
  @Input() toggleChecked: boolean;
  @Input() toggleDisabled: boolean;
  @Output() toggleChange = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  change(newValue) {
    this.toggleChecked = newValue;
    this.toggleChange.emit(this.toggleChecked);
  }
}
