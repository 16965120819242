// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  stripePublishableKey:
    'pk_test_51Kgj8DAsPRHHkYbeZoycclR1R2ENNz7xVsQJqwegdibYaV4DM5kAJhfHH8Ys8CjsEFdld127cca0afNhcLnFHawy00IxyT8uWi',
  // baseApiUrl: 'http://localhost:3001/api/v1',
  baseApiUrl: '/api/v1',
  firebase: {
    apiKey: 'AIzaSyBb15jrjZ1nATTYEubtTqQiokS3SaLAU8s',
    authDomain: 'myrep-a6125.firebaseapp.com',
    projectId: 'myrep-a6125',
    storageBucket: 'myrep-a6125.appspot.com',
    messagingSenderId: '983477896169',
    appId: '1:983477896169:web:994440f323053231a40178',
    measurementId: 'G-BZCN51BRTS',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
