<div class="profile-image-container">
  <img
    *ngIf="profileImage?.location"
    [src]="profileImage.location"
    alt="profile-pic"
    [style.width]="size + 'rem'"
    [style.height]="size + 'rem'"
  />
  <img
    *ngIf="!profileImage?.location"
    src="assets/images/profile-dummy.png"
    alt="profile-pic"
    [style.width]="size + 'rem'"
    [style.height]="size + 'rem'"
  />
</div>
