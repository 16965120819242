import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { Resource } from 'src/app/models/resource.model';
import { OnboardingService } from 'src/app/services';
import { ResourceService } from 'src/app/services/resource.service';

@Component({
  selector: 'my-rep-organization-form-onboard',
  templateUrl: './organization-form-onboard.component.html',
  styleUrls: ['./organization-form-onboard.component.scss']
})
export class OrganizationFormOnboardComponent implements OnInit {
  @Input() form: FormGroup;
  public nameFilterCtrl: FormControl = new FormControl();
  public resourceSearching: boolean = false;
  public filteredHospitals: Resource[] = [];
  public filteredDeviceCompanies: Resource[] = [];
  
  constructor(private resourceService: ResourceService, private onboardingService: OnboardingService) { }

  ngOnInit(): void {
    const organizationType = this.onboardingService.getOrgType();
    this.form.patchValue({ type: organizationType });
    this.initializeResourceSearch();
  }

  public initializeResourceSearch() {
    this.nameFilterCtrl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter((term) => term.length > 2),
        switchMap((term) => {
          this.resourceSearching = true;
          return this.resourceService.getResourcesByType(
            term,
            this.form.get('type').value
          );
        })
      )
      .subscribe((res) => {
        if (this.form.get('type').value === 'hospital') {
          this.updateFilteredHospitals(res);
        } else if (
          this.form.get('type').value === 'rep_company'
        ) {
          this.updateFilteredDeviceCompanies(res);
        }
        this.resourceSearching = false;
      });
  }


  public setDetails(event: any) {
    let resource = event.value;
    if (this.form.get('type').value === 'hospital') {
      this.form.patchValue({
        address: resource.address,
        city: resource.city,
        postalCode: resource.postalCode,
        contactNumber: resource.contactNumber,
        resource: resource._id,
        completeResource: resource,
      });
    } else if (this.form.get('type').value === 'rep_company') {
      this.form.patchValue({
        resource: resource._id,
        completeResource: resource,
      });
      if (resource.logo) {
        this.form.patchValue({
          profileImage: {
            name: null,
            type: null,
            location: resource.logo,
            key: null,
          },
        });
      }
    }
  }

  public updateFilteredHospitals(resources: Resource[]) {
    // when populating in the beginning, we need to reverse the order to show the most relevant results first
    resources.reverse();
    for (let i = 0; i < resources.length; i++) {
      if (
        this.filteredHospitals.findIndex(
          (hospital) => hospital._id === resources[i]._id
        ) === -1
      ) {
        this.filteredHospitals.unshift(resources[i]);
      }
    }
  }

  public updateFilteredDeviceCompanies(resources: Resource[]) {
    // when populating in the beginning, we need to reverse the order to show the most relevant results first
    resources.reverse();
    for (let i = 0; i < resources.length; i++) {
      if (
        this.filteredDeviceCompanies.findIndex(
          (deviceCompany) => deviceCompany._id === resources[i]._id
        ) === -1
      ) {
        this.filteredDeviceCompanies.unshift(resources[i]);
      }
    }
  }

}
