import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OnboardingService, UserService } from 'src/app/services';
import { StripeService } from 'src/app/services/stripe.service';
import { loadStripe } from '@stripe/stripe-js';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-onboarding-details',
  templateUrl: './onboarding-details.component.html',
  styleUrls: ['./onboarding-details.component.scss'],
})
export class OnboardingDetailsComponent implements OnInit {
  userType: string;
  orgFormGroup: FormGroup;
  userFormGroup: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private onboardingService: OnboardingService,
    private userService: UserService,
    private stripeService: StripeService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.onboardingService.setUserType = params.userType;
      this.userType = params.userType;
    });

    // Initialization of Organization Form
    this.orgFormGroup = new FormGroup({
      name: new FormControl('', [Validators.required]),
      domain: new FormControl('', [Validators.required]),
      website: new FormControl('', []),
      description: new FormControl('', []),
      type: new FormControl(null, [Validators.required]),
      city: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      postalCode: new FormControl('', [Validators.required]),
      contactNumber: new FormControl('', [Validators.required]),
      resource: new FormControl('', [Validators.required]),
      completeResource: new FormControl(null, []),
    });

    // Assigning Listeners for Changes in Organization Form
    this.orgFormGroup.get('type').valueChanges.subscribe((val) => {
      this.handleTypeChange(val);
    });

    this.userFormGroup = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, this.emailValidator()]),
      title: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      hospitals: new FormControl([], [Validators.required]),
      specialities: new FormControl(null, [Validators.required]),
    });
  }

  private handleTypeChange(type: string) {
    if (type === 'hospital') {
      this.orgFormGroup.addControl(
        'city',
        new FormControl('', [Validators.required])
      );
      this.orgFormGroup.addControl(
        'address',
        new FormControl('', [Validators.required])
      );
      this.orgFormGroup.addControl(
        'postalCode',
        new FormControl('', [Validators.required])
      );
      this.orgFormGroup.addControl(
        'contactNumber',
        new FormControl('', [Validators.required])
      );
      this.orgFormGroup.removeControl('profileImage');
    } else if (type === 'rep_company') {
      this.orgFormGroup.removeControl('city');
      this.orgFormGroup.removeControl('address');
      this.orgFormGroup.removeControl('postalCode');
      this.orgFormGroup.removeControl('contactNumber');
      this.orgFormGroup.addControl('profileImage', new FormControl(null, []));
    }
  }

  onOrgFormSubmit() {
    if (this.orgFormGroup.invalid) {
      return;
    }
    this.onboardingService.setOrganization(this.orgFormGroup.value);
  }

  onFinalSubmit() {
    if (this.userFormGroup.invalid || this.orgFormGroup.invalid) {
      return;
    }
    const email = `${this.userFormGroup.value.email}@${this.orgFormGroup.value.domain}`;
    let payload = {
      user: {
        ...this.userFormGroup.value,
        email: email,
      },
      organization: this.orgFormGroup.value,
    };
    if (this.userType === 'user-hcp') {
      this.userService.onboard(payload).subscribe((res) => {
        this.router.navigate(['/join/acknowledgement']);
      });
      // catch case has to be handled
    } else {
      // should send them to stripe checkout
      this.createCheckoutSession(payload);
    }
  }

  createCheckoutSession(payload) {
    this.stripeService
      .createCheckoutSession(payload)
      .subscribe(async (session: any) => {
        const stripe = await loadStripe(environment.stripePublishableKey);
        await stripe.redirectToCheckout({
          sessionId: session.id,
        });
      });
  }

  emailValidator() {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }
      const localPart = control.value;
      const email = `${localPart}@${this.orgFormGroup.get('domain').value}`;
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(?:[a-zA-Z]{2,}|(?!))$/;
      const isValid = regex.test(email);
      return isValid
        ? null
        : { invalidCombinedEmail: { value: control.value } };
    };
  }
}
