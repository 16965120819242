<div class="signup-container">
  <div class="fs-28 f-900 mb-2 l-spacing">Welcome !</div>
  <div class="signup-details-container">
    <form
      [formGroup]="signupDFormGroup"
      (ngSubmit)="!isLoading && activate()"
      novalidate
    >
      <app-image-selector
        [title]="'Upload your profile pic here'"
        [image]="signupDFormGroup?.get('profileImage')?.value"
        [path]="'users/' + user._id"
        (uploaded)="imageUploaded($event)"
        (removed)="imageRemoved()"
      ></app-image-selector>
      <div class="mt-2">
        <mat-form-field color="primary" appearance="outline">
          <mat-label>Title</mat-label>
          <input
            type="text"
            formControlName="title"
            matInput
            placeholder="Enter Title"
          />
        </mat-form-field>
      </div>
      <div class="mt-2">
        <mat-form-field color="primary" appearance="outline">
          <mat-label>City</mat-label>
          <input
            type="text"
            formControlName="city"
            matInput
            placeholder="Enter City"
          />
        </mat-form-field>
      </div>
      <div class="password-field mt-2" style="position: relative">
        <mat-form-field color="primary" appearance="outline">
          <mat-label>Password</mat-label>
          <input
            type="password"
            formControlName="password"
            matInput
            placeholder="Enter Password"
            [type]="showPassword ? 'text' : 'password'"
          />
          <mat-icon
            matSuffix
            color="primary"
            class="pointer"
            (click)="showPassword = !showPassword"
            >{{ showPassword ? "visibility" : "visibility_off" }}</mat-icon
          >
        </mat-form-field>
        <div class="password-validator">
          <my-rep-password-validation
            [passwordControl]="signupDFormGroup.controls['password']"
          ></my-rep-password-validation>
        </div>
      </div>
      <div class="mt-2">
        <mat-form-field color="primary" appearance="outline">
          <mat-label>Confirm Password</mat-label>
          <input
            type="password"
            formControlName="confirmPassword"
            matInput
            placeholder="Confirm Password"
            [type]="showConfirmPassword ? 'text' : 'password'"
          />
          <mat-icon
            matSuffix
            color="primary"
            class="pointer"
            (click)="showConfirmPassword = !showConfirmPassword"
            >{{
              showConfirmPassword ? "visibility" : "visibility_off"
            }}</mat-icon
          >
        </mat-form-field>
      </div>
      <div class="d--f jc--fe mt-2">
        <button
          color="primary"
          mat-raised-button
          type="submit"
          [style.opacity]="isLoading ? 0.5 : 1"
        >
          Update
          <mat-icon class="ml-2" *ngIf="isLoading">
            <mat-spinner color="accent" diameter="20"> </mat-spinner>
          </mat-icon>
        </button>
      </div>
    </form>
  </div>
</div>
