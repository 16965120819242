import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OnboardingService } from 'src/app/services';

@Component({
  selector: 'app-onboarding-start',
  templateUrl: './onboarding-start.component.html',
  styleUrls: ['./onboarding-start.component.scss'],
})
export class OnboardingStartComponent implements OnInit {
  userTypes: any;

  constructor(
    private onboardingService: OnboardingService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.userTypes = this.onboardingService.getUserTypes;
  }

  public onUserTypeSelect(userType: string): void {
    this.router.navigate([`/join/details/${userType}`]);
  }
}
