<div class="container">
  <div class="header">
    <img
      class="logo"
      src="assets/images/logo.svg"
      alt="logo"
      [routerLink]="['/join']"
    />
  </div>
  <div class="content">
    <router-outlet></router-outlet>
  </div>
</div>
