<div class="signup-container">
  <div class="fs-28 f-900 mb-2 l-spacing">Sign up</div>
  <form
    [formGroup]="signupFormGroup"
    (ngSubmit)="!isLoading && signup()"
    novalidate
  >
    <div>
      <mat-form-field color="primary" appearance="outline">
        <mat-label>Member ID</mat-label>
        <input
          type="number"
          formControlName="memberId"
          matInput
          placeholder="Enter Member ID"
        />
      </mat-form-field>
    </div>
    <div class="mt-2">
      <mat-form-field color="primary" appearance="outline">
        <mat-label>Invitation Key</mat-label>
        <input
          formControlName="invitationCode"
          matInput
          placeholder="Enter Invitation Key"
          [type]="showPassword ? 'text' : 'password'"
          (keypress)="onKeyPress($event)"
        />
        <mat-icon
          matSuffix
          color="primary"
          class="pointer"
          (click)="toggleVisibility()"
          >{{ showPassword ? "visibility" : "visibility_off" }}</mat-icon
        >
      </mat-form-field>
    </div>
    <div class="mt-1">
      <mat-checkbox color="primary" formControlName="terms">
        <span
          (mousedown)="$event.stopPropagation()"
          (click)="viewAgreement($event, 'tAndC')"
          class="primary-color"
        >
          Terms and Privacy Policy
        </span>
      </mat-checkbox>
    </div>
    <div class="mt-2 d--f jc--sb">
      <button [routerLink]="'/'" color="accent" mat-raised-button type="button">
        Sign In
      </button>
      <button
        color="primary"
        mat-raised-button
        type="submit"
        [style.opacity]="isLoading ? 0.5 : 1"
      >
        Sign Up
        <mat-icon class="ml-2" *ngIf="isLoading">
          <mat-spinner color="accent" diameter="20"> </mat-spinner>
        </mat-icon>
      </button>
    </div>
  </form>
</div>
