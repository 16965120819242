import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { SnackbarService, UserService } from 'src/app/services';
import { ConfirmationDialogComponent } from '../../shared';

@Component({
  selector: 'app-import-form',
  templateUrl: './import-form.component.html',
  styleUrls: ['./import-form.component.scss'],
})
export class ImportFormComponent implements OnInit {
  formData: FormData = new FormData();
  isLoading: boolean = false;
  file: File;
  constructor(
    private userService: UserService,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    private dialogRef: MatDialogRef<ImportFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.formData.append('organizationId', this.data.organizationId);
  }

  public import() {
    if (!this.file) {
      this.snackbarService.openSnackBar('Please select a file', 'Close');
      this.isLoading = false;
      return;
    }
    this.isLoading = true;
    this.userService.import(this.formData).subscribe(
      (res) => {
        this.isLoading = false;
        this.dialogRef.close();
        let data = {
          icon: 'tick',
          title: 'Import Successful!',
          message: 'Users have been imported successfully.',
        };
        this.dialog.open(ConfirmationDialogComponent, {
          width: '40rem',
          autoFocus: false,
          data: data,
        });
      },
      (err) => {
        this.isLoading = false;
        this.snackbarService.openSnackBar(err.error.message, 'Close');
      }
    );
  }

  public onFileChange(files) {
    this.file = files[0];
    this.formData.append('file', files[0]);
  }

  public removeFile() {
    this.file = null;
    this.formData.delete('file');
  }

  public cancelForm() {
    this.dialogRef.close();
  }
}
