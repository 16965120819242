<div class="confirmation-dialog-container">
  <div *ngIf="icon" class="d--f jc--c mb-2">
    <img
      style="height: 6rem; width: 6rem"
      src="assets/icons/{{ icon }}.svg"
      alt=""
    />
  </div>
  <div class="text-center">
    <p *ngIf="title" class="fs-24 f-500 grey-color mb-2">{{ title }}</p>
    <p *ngIf="message" class="fs-18 f-300 grey-color">{{ message }}</p>
  </div>
  <div class="d--f jc--sb ai--c mt-2" *ngIf="isActionNeeded">
    <button mat-raised-button color="accent" (click)="onDismiss()">
      {{ cancelText }}
    </button>
    <button mat-raised-button color="primary" (click)="onConfirm()">
      {{ actionText }}
    </button>
  </div>
  <mat-icon class="close-icon" color="primary" (click)="close()"
    >close</mat-icon
  >
</div>
