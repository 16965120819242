import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-onboarding-acknowledgement',
  templateUrl: './onboarding-acknowledgement.component.html',
  styleUrls: ['./onboarding-acknowledgement.component.scss'],
})
export class OnboardingAcknowledgementComponent implements OnInit {
  success: boolean;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.success =
        params['success'] === 'true' || params['success'] === undefined;
    });
  }
}
