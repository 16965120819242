import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Img } from 'src/app/models';
import { AuthService } from 'src/app/services/auth.service';
import { DocumentService } from 'src/app/services/document.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UserService } from 'src/app/services/user.service';
import { CustomValidators } from 'src/app/validators/custom-validators';

@Component({
  selector: 'my-rep-signup-details',
  templateUrl: './signup-details.component.html',
  styleUrls: ['./signup-details.component.scss'],
})
export class SignupDetailsComponent implements OnInit {
  public signupDFormGroup: FormGroup;
  public showPassword: boolean = false;
  public showConfirmPassword: boolean = false;
  public user: any;
  public isLoading: boolean = false;
  public isFileUploading: boolean = false;
  public isFileDeleting: boolean = false;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private snackbarService: SnackbarService,
    private router: Router,
    public docService: DocumentService
  ) {
    this.user = this.router.getCurrentNavigation()?.extras?.state?.user ?? null;
  }

  public ngOnInit(): void {
    this.signupDFormGroup = new FormGroup({
      profileImage: new FormControl(null, []),
      title: new FormControl(this.user?.title ?? '', [Validators.required]),
      city: new FormControl(this.user?.city ?? '', [Validators.required]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        CustomValidators.patternValidator(/\d/, { numberErr: true }),
        CustomValidators.patternValidator(/[A-Z]/, { capitalCaseErr: true }),
        CustomValidators.patternValidator(/[a-z]/, { smallCaseErr: true }),
        CustomValidators.patternValidator(
          /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
          {
            specialCharsErr: true,
          }
        ),
      ]),
      confirmPassword: new FormControl('', [Validators.required]),
    });
    const user = this.userService.getUserLocal();
    if (user) {
      this.signupDFormGroup.patchValue({
        title: user.title,
        city: user.city,
      });
    }
  }

  public activate() {
    if (!this.signupDFormGroup.valid) {
      return;
    }
    this.isLoading = true;
    this.authService.activate(this.signupDFormGroup.value).subscribe(
      (res: any) => {
        this.userService.saveUserLocal(res.data.user);
        const user = this.userService.getUserLocal();
        if (this.userService.hasRole('superadmin')) {
          this.router.navigate(['/home/organizations']);
        } else {
          this.router.navigate(['/home/organizations', user.organization]);
        }
        this.isLoading = false;
        this.snackbarService.openSnackBar(res.message, 'Close');
      },
      (err) => {
        this.isLoading = false;
        this.snackbarService.openSnackBar(err.error.message, 'Close');
      }
    );
  }

  public imageUploaded(document: Img) {
    this.signupDFormGroup.patchValue({
      profileImage: document,
    });
  }

  public imageRemoved() {
    this.signupDFormGroup.patchValue({
      profileImage: null,
    });
  }

  public backToSignupPage() {
    this.router.navigate(['signup']);
  }
}
