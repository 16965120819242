<div
  *ngIf="passwordControl.dirty"
  class="validator-container"
  [ngClass]="isChangePassword ? 'validator-width-2' : 'validator-width'"
>
  <div
    class="mt-1 d--f ai--c"
    [style]="{
      color:
        passwordControl.hasError('required') ||
        passwordControl.hasError('minlength')
          ? '#F44336'
          : '#43A047'
    }"
  >
    <mat-icon class="mr-1">{{
      passwordControl.hasError("required") ||
      passwordControl.hasError("minlength")
        ? "cancel"
        : "check_circle"
    }}</mat-icon>
    <span>Minimum 8 characters</span>
  </div>
  <div
    class="mt-1 d--f ai--c"
    [style]="{
      color:
        passwordControl.hasError('required') ||
        passwordControl.hasError('specialCharsErr')
          ? '#F44336'
          : '#43A047'
    }"
  >
    <mat-icon class="mr-1">{{
      passwordControl.hasError("required") ||
      passwordControl.hasError("specialCharsErr")
        ? "cancel"
        : "check_circle"
    }}</mat-icon>
    <span>Atleast one special character</span>
  </div>
  <div
    class="mt-1 d--f ai--c"
    [style]="{
      color:
        passwordControl.hasError('required') ||
        passwordControl.hasError('capitalCaseErr')
          ? '#F44336'
          : '#43A047'
    }"
  >
    <mat-icon class="mr-1">{{
      passwordControl.hasError("required") ||
      passwordControl.hasError("capitalCaseErr")
        ? "cancel"
        : "check_circle"
    }}</mat-icon>
    <span>Atleast one capital character</span>
  </div>
  <div
    class="mt-1 d--f ai--c"
    [style]="{
      color:
        passwordControl.hasError('required') ||
        passwordControl.hasError('smallCaseErr')
          ? '#F44336'
          : '#43A047'
    }"
  >
    <mat-icon class="mr-1">{{
      passwordControl.hasError("required") ||
      passwordControl.hasError("smallCaseErr")
        ? "cancel"
        : "check_circle"
    }}</mat-icon>
    <span>Atleast one small character</span>
  </div>
  <div
    class="mt-1 d--f ai--c"
    [style]="{
      color:
        passwordControl.hasError('required') ||
        passwordControl.hasError('numberErr')
          ? '#F44336'
          : '#43A047'
    }"
  >
    <mat-icon class="mr-1">{{
      passwordControl.hasError("required") ||
      passwordControl.hasError("numberErr")
        ? "cancel"
        : "check_circle"
    }}</mat-icon>
    <span>Atleast one number</span>
  </div>
</div>
