<div>
  <div *ngIf="title" class="grey-color mb-1 f-700 mt-2">{{ title }}</div>
  <div>
    <img
      class="placeholder-img pointer"
      *ngIf="!image"
      src="assets/images/upload-placeholder.svg"
      alt="upload placeholder"
      (click)="fileInput.click()"
    />
    <img
      class="profile-img pointer"
      *ngIf="image"
      [src]="image?.location"
      alt="profile image"
    />
    <input
      type="file"
      hidden
      #fileInput
      accept="image/png, image/gif, image/jpeg , image/jpg"
      (change)="fileChangeEvent($event)"
    />
  </div>
  <div class="d--f ai--c f--g-1 mt-1">
    <button
      type="button"
      color="primary"
      mat-stroked-button
      (click)="fileInput.click()"
    >
      Browse
    </button>
    <button
      *ngIf="image"
      type="button"
      color="warn"
      mat-stroked-button
      (click)="removeDocument()"
    >
      Remove
    </button>
    <div class="d--f ai--c ml-2 mr-2" *ngIf="isFileUploading">
      <mat-spinner class="mr-1" diameter="20"></mat-spinner>
      <span class="primary-color"> uploading... </span>
    </div>
    <div class="d--f ai--c ml-2" *ngIf="isFileDeleting">
      <mat-spinner color="warn" class="mr-1" diameter="20"></mat-spinner>
      <span class="warn-color"> deleting... </span>
    </div>
  </div>
</div>
