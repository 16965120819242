import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'my-rep-empty-screen',
  templateUrl: './empty-screen.component.html',
  styleUrls: ['./empty-screen.component.scss'],
})
export class EmptyScreenComponent implements OnInit {
  @Input() options: {
    title: string;
    imagePath: string;
  };

  constructor() {}

  ngOnInit(): void {}
}
