import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-crop',
  templateUrl: './image-crop.component.html',
  styleUrls: ['./image-crop.component.scss'],
})
export class ImageCropComponent implements OnInit {
  imageChangedEvent: any = '';
  originalFileName: string = '';
  croppedImage: any = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ImageCropComponent,
    public dialogRef: MatDialogRef<ImageCropComponent>
  ) {}

  ngOnInit(): void {
    this.imageChangedEvent = this.data.imageChangedEvent;
    this.originalFileName = this.data.originalFileName;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  public onSave(): void {
    const blob = this.dataURItoBlob(this.croppedImage);
    this.dialogRef.close({
      blob,
      originalFileName: this.originalFileName,
    });
  }

  public onCancel(): void {
    this.dialogRef.close(false);
  }

  public close() {
    this.dialogRef.close();
  }

  public dataURItoBlob(dataURI: string) {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }
}
