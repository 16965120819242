import { Component, Input, OnInit } from '@angular/core';
import { Img } from 'src/app/models';

@Component({
  selector: 'app-profile-image-preview',
  templateUrl: './profile-image-preview.component.html',
  styleUrls: ['./profile-image-preview.component.scss'],
})
export class ProfileImagePreviewComponent implements OnInit {
  @Input() profileImage: Img;
  @Input() size: string;

  constructor() {}

  ngOnInit(): void {}
}
