import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { ViewService } from './view.service';
import { Organization } from '../models';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private users: any = [];
  public usersChanged = new Subject<any[]>();
  public totalLengthChanged = new Subject<number>();
  public localUserChanged = new Subject<any>();

  constructor(private http: HttpClient, public viewService: ViewService) {}

  getUsersByOrganizationId(id) {
    return combineLatest([
      this.viewService.userPaginationOptions$,
      this.viewService.userSearchTerm$,
      this.viewService.isActiveTabSelected$,
    ]).pipe(
      take(1),
      switchMap(([paginationOptions, searchTerm, isActive]) => {
        let pageIndex = paginationOptions.pageIndex;
        let pageSize = paginationOptions.pageSize;
        let url = `/users/organization/${id}?page=${
          pageIndex + 1
        }&limit=${pageSize}&isActive=${isActive}`;
        if (searchTerm) {
          url += `&searchTerm=${searchTerm}`;
        }
        return this.http.get(url);
      }),
      tap((res: any) => {
        this.users = res.data.users;
        this.usersChanged.next(this.users.slice());
        this.totalLengthChanged.next(res.data.totalLength);
      })
    );
  }

  createUser(user: any, organizationId: string) {
    return this.http.post('/users', user).pipe(
      tap(() => {
        this.getUsersByOrganizationId(organizationId).subscribe();
      })
    );
  }

  updateUser(user: any, organizationId?: string) {
    return this.http.put(`/users/${user._id}`, user).pipe(
      tap(() => {
        if (organizationId) {
          this.getUsersByOrganizationId(organizationId).subscribe();
        }
      })
    );
  }

  deleteUser(id: string, organizationId: string) {
    return this.http.delete(`/users/${id}`).pipe(
      tap(() => {
        this.getUsersByOrganizationId(organizationId).subscribe();
      })
    );
  }

  onboard(payload: { user: any; organization: Organization }) {
    return this.http.post('/users/onboard', payload);
  }

  import(formData: FormData) {
    return this.http.post('/users/import', formData);
  }

  getUserLocal() {
    return JSON.parse(localStorage.getItem('user'));
  }

  saveUserLocal(user: any) {
    localStorage.setItem('user', JSON.stringify(user));
    this.localUserChanged.next(user);
  }

  destroyUserLocal() {
    localStorage.removeItem('user');
  }

  getRoleOfUserLocal() {
    let user = this.getUserLocal();
    if (user.role.key == 'admin' && user.isPrimeAdmin) {
      return 'primeadmin';
    }
    return this.getUserLocal().role.key;
  }

  hasRole(roleKeys: string | string[]): boolean {
    const userRoleKey = this.getRoleOfUserLocal();
    if (Array.isArray(roleKeys)) {
      return roleKeys.includes(userRoleKey);
    } else {
      return userRoleKey === roleKeys;
    }
  }

  setUserDetailsLocal(userDetails: any) {
    let user = this.getUserLocal();
    user.title = userDetails.title;
    user.firstName = userDetails.firstName;
    user.lastName = userDetails.lastName;
    user.email = userDetails.email;
    user.city = userDetails.city;
    user.profileImage = userDetails.profileImage;
    this.saveUserLocal(user);
  }
}
