<div class="p-1">
  <div class="d--f jc--sb pb-1">
    <div class="grey-color f-700 fs-22">Crop Image</div>
    <div>
      <mat-icon color="primary" class="pointer f-700" (click)="close()"
        >close</mat-icon
      >
    </div>
  </div>
  <div>
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="false"
      format="png"
      (imageCropped)="imageCropped($event)"
    ></image-cropper>
  </div>
  <div class="d--f jc--sb ai--c mt-2">
    <button mat-raised-button color="accent" type="button" (click)="onCancel()">
      Cancel
    </button>
    <button mat-raised-button color="primary" type="submit" (click)="onSave()">
      Save
    </button>
  </div>
</div>
