<div class="reset-password-container">
  <div class="mb-2">
    <p class="fs-28 f-900 l-spacing">Reset Password</p>
    <p class="mt-2">Please enter the email address you used to register.</p>
    <p>We will send you a new password.</p>
  </div>
  <form
    [formGroup]="resetPasswordFormGroup"
    (ngSubmit)="!isLoading && retrievePassword()"
  >
    <div>
      <mat-form-field color="primary" appearance="outline">
        <mat-label>Email address</mat-label>
        <input
          type="email"
          formControlName="email"
          matInput
          placeholder="Enter email address"
        />
      </mat-form-field>
    </div>
    <div class="mt-2">
      <mat-form-field color="primary" appearance="outline">
        <mat-label>Member ID #</mat-label>
        <input
          type="number"
          formControlName="memberId"
          matInput
          placeholder="Enter Member ID"
        />
      </mat-form-field>
    </div>
    <div class="mt-2 d--f jc--fe">
      <button
        color="primary"
        mat-raised-button
        type="submit"
        [style.opacity]="isLoading ? 0.5 : 1"
      >
        Retrieve
        <mat-icon class="ml-2" *ngIf="isLoading">
          <mat-spinner color="accent" diameter="20"> </mat-spinner>
        </mat-icon>
      </button>
    </div>
  </form>
</div>
