<div class="p-1">
  <div class="d--f jc--sb bb-1 pb-1">
    <div class="grey-color f-700 fs-22">Import Users</div>
    <div>
      <mat-icon color="primary" class="pointer f-700" (click)="cancelForm()"
        >close</mat-icon
      >
    </div>
  </div>
  <div class="mt-2">
    <div class="grey-color mb-1">Choose the file you want to import</div>
    <div class="d--f jc--c pt-4 pb-4 file-container" *ngIf="!file">
      <div class="d--f fd--c ai--c f--g-1 fs-14 grey-color">
        <img
          style="height: 4rem; width: 4rem"
          src="assets/icons/upload.svg"
          alt=""
        />
        <p>Drag a file here or</p>
        <a class="pointer" (click)="fileInput.click()">Select a file </a>
        <input
          type="file"
          style="display: none"
          #fileInput
          name="file"
          accept=".xlsx, .xls, .csv"
          (change)="onFileChange(fileInput.files)"
        />
      </div>
    </div>
    <div class="d--f jc--sb ai--c p-1 file-container" *ngIf="file">
      <div class="d--f ai--c f--g-1">
        <img
          style="height: 2rem; object-fit: contain"
          src="assets/images/excel.png"
          alt="excel_icon"
        />
        <p>{{ file.name }}</p>
      </div>
      <div>
        <mat-icon color="warn" class="pointer" (click)="removeFile()"
          >delete</mat-icon
        >
      </div>
    </div>
    <div class="d--f jc--fe mt-2">
      <button
        mat-raised-button
        color="primary"
        (click)="!isLoading && import()"
        [style.opacity]="isLoading ? 0.5 : 1"
      >
        {{ isLoading ? "Processing..." : "Import" }}
        <mat-icon class="ml-2" *ngIf="isLoading">
          <mat-spinner color="accent" diameter="20"> </mat-spinner>
        </mat-icon>
      </button>
    </div>
  </div>
</div>
