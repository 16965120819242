import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Organization } from 'src/app/models/organization.model';
import { OrganizationService } from 'src/app/services/organization.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ViewService } from 'src/app/services/view.service';
import { ConfirmationDialogComponent } from '../../shared/index';
import { OrganizationFormComponent } from '../organization-form/organization-form.component';

@Component({
  selector: 'my-rep-organization-management',
  templateUrl: './organization-management.component.html',
  styleUrls: ['./organization-management.component.scss'],
})
export class OrganizationManagementComponent implements OnInit {
  public organizations: Organization[] = [];
  public isLoading: boolean = false;
  public organizationTableCols: string[] = [
    'company',
    'registeredDate',
    'numberOfUsers',
    'status',
  ];
  public dataSource = new MatTableDataSource<any>([]);
  public userCount: number;
  public adminCount: number;
  public isStatusUpdating: boolean = false;
  public toggleIndexId: string;
  public pageSizeOptions: number[] = [6, 12, 24];
  public totalLength: number;
  public searchTerm: string;
  private searchSubject = new Subject<string>();

  public emptyScreenOptions = {
    title: 'No Organizations',
    imagePath: 'assets/images/organization-empty-screen.svg',
  };

  constructor(
    private dialog: MatDialog,
    private oService: OrganizationService,
    private snackbarService: SnackbarService,
    private router: Router,
    public viewService: ViewService
  ) {}

  public ngOnInit(): void {
    this.getOrganizations();
    this.initializeSearch();
    this.oService.organizationsChanged.subscribe({
      next: (res) => {
        this.organizations = res;
        this.dataSource.data = this.organizations;
        this.isLoading = false;
      },
      error: (err) => {
        this.snackbarService.openSnackBar(err.error.message, 'error');
        this.isLoading = false;
      },
    });
    this.oService.totalLengthChanged.subscribe({
      next: (res) => {
        this.totalLength = res;
      },
    });
  }

  public getOrganizations() {
    this.isLoading = true;
    this.oService.getOrganizations().subscribe();
  }

  public initializeSearch() {
    this.searchSubject
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.viewService.resetOrgPaginationOptions();
        this.getOrganizations();
      });
  }

  public onPageChange(event: PageEvent) {
    this.viewService.setOrgPaginationOptions({
      pageSize: event.pageSize,
      pageIndex: event.pageIndex,
    });
    this.getOrganizations();
  }

  public onSearchChange() {
    this.viewService.setOrgSearchTerm(this.searchTerm);
    this.searchSubject.next(this.searchTerm);
  }

  public updateTypeFilter(type) {
    this.viewService.setSelectedType(type);
    this.viewService.resetOrgPaginationOptions();
    this.getOrganizations();
  }

  public openUserManagement(id) {
    this.router.navigate(['/home/organizations', id]);
  }

  public openOrganizationForm($event) {
    $event.stopPropagation();
    const dialogRef = this.dialog.open(OrganizationFormComponent, {
      width: '660px',
      autoFocus: false,
    });
  }

  public openDeleteConfirmation($event, organization) {
    $event.stopPropagation();
    let data = {
      icon: 'close',
      title: 'Delete Organization',
      message: `Are you sure you want to delete the organization ${organization.name}?`,
      isActionNeeded: true,
      actionText: 'Delete',
      cancelText: 'Cancel',
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '40rem',
      autoFocus: false,
      data: data,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.deleteOrganization(organization._id);
      }
    });
  }

  public deleteOrganization(id) {
    this.oService.deleteOrganization(id).subscribe({
      next: (res: any) => {
        let data = {
          icon: 'tick',
          title: 'Delete Successful',
          message: `Selected organization have been deleted. This process can not be undone now`,
        };
        this.dialog.open(ConfirmationDialogComponent, {
          width: '40rem',
          autoFocus: false,
          data: data,
        });
      },
      error: (err: any) => {
        this.snackbarService.openSnackBar(err.error.message, 'error');
      },
    });
  }

  public updateStatus($event, organization: Organization) {
    let payload = {
      _id: organization._id,
      isActive: $event,
    };
    this.toggleIndexId = organization._id;
    this.isStatusUpdating = true;
    this.oService.updateOrganizationStatus(payload).subscribe(
      (res: any) => {
        let data = {};
        if (!res?.data?.organization?.isActive) {
          data = {
            icon: 'organization',
            title: 'Organization Disabled',
            message:
              'All the users of this organization will be disabled and logged out.',
            isActionNeeded: false,
          };
        } else {
          data = {
            icon: 'organization',
            title: 'Organization Enabled',
            message:
              'All the users of this organization will be enabled and should be able to login.',
            isActionNeeded: false,
          };
        }
        this.dialog.open(ConfirmationDialogComponent, {
          width: '40rem',
          autoFocus: false,
          data: data,
        });
        this.isStatusUpdating = false;
      },
      () => {
        this.isStatusUpdating = false;
      }
    );
  }

  public openOrganizationInfo(id) {
    this.router.navigate(['/home/organization-info', id]);
  }

  public trackByFn(index, organization) {
    return organization._id;
  }
}
