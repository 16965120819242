import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  AuthService,
  OrganizationService,
  UserService,
} from 'src/app/services/index';
import {
  ChangePasswordComponent,
  ConfirmationDialogComponent,
} from 'src/app/components';
import { Observable } from 'rxjs';
import { Organization } from 'src/app/models';

@Component({
  selector: 'my-rep-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public user: any;
  public organization$: Observable<Organization>;
  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private userService: UserService,
    private oService: OrganizationService,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.user = this.userService.getUserLocal();
    if (this.userService.hasRole(['admin', 'primeadmin'])) {
      this.organization$ = this.oService.getOrganizationById(
        this.user.organization
      );
    }
    this.userService.localUserChanged.subscribe((user) => {
      this.user = user;
    });
  }

  public openAgreement(type) {
    this.router.navigate(['home/manage-agreement'], {
      queryParams: { type },
    });
  }

  public navigateToProfile() {
    this.router.navigate(['home/profile']);
  }

  public navigateToHome() {
    if (this.userService.hasRole('superadmin')) {
      this.router.navigate(['home/organizations']);
    } else {
      this.router.navigate(['home/organizations', this.user.organization]);
    }
  }

  public logout() {
    this.authService.logout();
    let data = {
      icon: 'logout',
      title: 'Logged out successfully !',
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '40rem',
      autoFocus: false,
      data: data,
    });
  }

  public changePassword() {
    const changePasswordRef = this.dialog.open(ChangePasswordComponent, {
      width: '40rem',
      autoFocus: false,
    });
  }
}
