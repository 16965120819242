<mat-card class="mat-elevation-z8 acknowledgment-card">
  <div class="icon-container success-icon" *ngIf="success">
    <img src="assets/icons/tick.svg" alt="Success" />
  </div>
  <div class="icon-container failure-icon" *ngIf="!success">
    <img src="assets/icons/close.svg" alt="Failure" />
  </div>
  <mat-card-content class="content-container">
    <div class="title" *ngIf="success">Account Created</div>
    <div class="title" *ngIf="!success">Account Creation Failed</div>
    <div class="subtitle">
      <ng-container *ngIf="success">
        Please check your email for instructions<br />
        or updates on your account.
      </ng-container>
      <ng-container *ngIf="!success">
        There was an error creating your account.<br />
        Please try again later.
      </ng-container>
    </div>
  </mat-card-content>
</mat-card>
