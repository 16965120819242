import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { JwtService } from 'src/app/services/jwt.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'my-rep-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  public signupFormGroup: FormGroup;
  public showPassword: boolean = false;
  public isLoading: boolean = false;

  constructor(
    private authService: AuthService,
    private jwtService: JwtService,
    private userService: UserService,
    private snackbarService: SnackbarService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.signupFormGroup = new FormGroup({
      memberId: new FormControl('', [Validators.required]),
      invitationCode: new FormControl('', [Validators.required]),
      terms: new FormControl(false, [Validators.requiredTrue]),
    });
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.memberId && params.invitationCode) {
        this.signupFormGroup.patchValue({
          memberId: params.memberId,
          invitationCode: params.invitationCode,
        });
      }
    });
  }

  public onKeyPress(event) {
    if (event.charCode < 48 || event.charCode > 57) {
      event.preventDefault();
    }
  }

  public signup() {
    if (!this.signupFormGroup.get('terms').value) {
      this.snackbarService.openSnackBar(
        'Please accept the terms and conditions',
        'Close'
      );
      return;
    }
    if (this.signupFormGroup.valid) {
      if (
        this.signupFormGroup.value.password !==
        this.signupFormGroup.value.confirmPassword
      ) {
        this.snackbarService.openSnackBar(
          'Password and confirm password are not same',
          'Close'
        );
        return;
      }
      let payload = {
        memberId: this.signupFormGroup.value.memberId,
        invitationCode: this.signupFormGroup.value.invitationCode,
      };
      this.isLoading = true;
      this.authService.signup(payload).subscribe(
        (res: any) => {
          this.snackbarService.openSnackBar(res.message, 'Close');
          this.jwtService.saveToken(res.token);
          this.userService.saveUserLocal(res.data.user);
          let navigationExtras: NavigationExtras = {
            state: {
              user: res.data.user,
            },
          };
          this.isLoading = false;
          this.router.navigate(['/signup-details'], navigationExtras);
        },
        (err) => {
          this.isLoading = false;
          this.snackbarService.openSnackBar(err.error.message, 'Close');
        }
      );
    }
  }

  public viewAgreement(event, type) {
    event.preventDefault();
    event.stopPropagation();
    window.open(`/view-agreement?type=${type}`, '_blank');
  }

  public toggleVisibility(): void {
    this.showPassword = !this.showPassword;
  }
}
