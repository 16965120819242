<mat-card class="mat-elevation-z8">
  <mat-card-content>
    <mat-horizontal-stepper [linear]="true">
      <mat-step [stepControl]="orgFormGroup">
        <form [formGroup]="orgFormGroup" (ngSubmit)="onOrgFormSubmit()">
          <ng-template matStepLabel>Organization Details</ng-template>
          <my-rep-organization-form-onboard
            [form]="orgFormGroup"
          ></my-rep-organization-form-onboard>
          <div class="d--f jc--fe mt-2">
            <button color="primary" mat-raised-button matStepperNext>
              Next
            </button>
          </div>
        </form>
      </mat-step>

      <mat-step [stepControl]="userFormGroup">
        <form [formGroup]="userFormGroup" (ngSubmit)="onFinalSubmit()">
          <ng-template matStepLabel>User Details</ng-template>
          <my-rep-user-form-onboard
            [form]="userFormGroup"
          ></my-rep-user-form-onboard>
          <div class="d--f jc--sb f--g-2 mt-2">
            <button color="primary" mat-stroked-button matStepperPrevious>
              Back
            </button>
            <button color="primary" mat-raised-button matStepperNext>
              {{ userType === "user-hcp" ? "Save" : "Pay" }}
            </button>
          </div>
        </form>
      </mat-step>
    </mat-horizontal-stepper>
  </mat-card-content>
</mat-card>
