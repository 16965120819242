import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(private http: HttpClient) {}

  //  upload single or multiple documents
  upload(params) {
    let url = params.path ? `/documents?path=${params.path}` : `/documents`;
    return this.http.post(url, params.formData);
  }

  // delete single document
  delete(key: string) {
    return this.http.request('delete', '/documents', { body: { key } });
  }
}
