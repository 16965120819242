<div class="onboarding-start">
  <div class="get-started primary-color">Get Started</div>
  <p class="instruction">Please select an option to proceed</p>
  <div class="options">
    <div
      class="card pointer"
      *ngFor="let type of userTypes"
      (click)="onUserTypeSelect(type.key)"
    >
      <div class="card-content">
        <img class="user-image" [src]="type?.imageUrl" alt="" />
        <div class="title">
          {{ type.name }}
        </div>
        <div class="subtitle">
          {{ type.description }}
        </div>
      </div>
    </div>
  </div>
</div>
