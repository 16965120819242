import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { JwtService } from 'src/app/services/jwt.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'my-rep-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginFormGroup: FormGroup;
  public showPassword: boolean = false;
  public isLoading: boolean = false;

  constructor(
    private authService: AuthService,
    private jwtService: JwtService,
    private userService: UserService,
    private snackbarService: SnackbarService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.loginFormGroup = new FormGroup({
      memberId: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.memberId && params.password) {
        this.loginFormGroup.patchValue({
          memberId: params.memberId,
          password: params.password,
        });
      }
    });
  }

  public login() {
    if (this.loginFormGroup.valid) {
      this.isLoading = true;
      this.authService.login(this.loginFormGroup.value).subscribe(
        (res: any) => {
          this.jwtService.saveToken(res.token);
          const user = res.data.user;
          this.userService.saveUserLocal(res.data.user);
          if (this.userService.hasRole('superadmin')) {
            this.router.navigate(['/home/organizations']);
          } else {
            this.router.navigate(['/home/organizations', user.organization]);
          }
          this.isLoading = false;
          this.snackbarService.openSnackBar(res.message, 'Close');
        },
        (err) => {
          this.isLoading = false;
          this.snackbarService.openSnackBar(err.error.message, 'Close');
        }
      );
    }
  }

  public toggleVisibility(): void {
    this.showPassword = !this.showPassword;
  }
}
