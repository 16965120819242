import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { OrganizationService, SnackbarService } from 'src/app/services/index';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  pairwise,
  switchMap,
  tap,
} from 'rxjs/operators';
import { Img, Organization } from 'src/app/models';
import { DocumentService } from 'src/app/services/document.service';

@Component({
  selector: 'my-rep-organization-details',
  templateUrl: './organization-details.component.html',
  styleUrls: ['./organization-details.component.scss'],
})
export class OrganizationDetailsComponent implements OnInit, OnDestroy {
  public organizationFormGroup: FormGroup;
  public organizationId: string;
  public organization: any;
  private changesSubscription: Subscription;
  private statusSubscription: Subscription;
  private isAutoSavingSubject = new BehaviorSubject<boolean>(false);
  public isAutoSaving$ = this.isAutoSavingSubject.asObservable();
  @Output() dataLoaded = new EventEmitter();
  public isFileUploading: boolean = false;
  public isFileDeleting: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private oService: OrganizationService,
    public snackbarService: SnackbarService,
    private docService: DocumentService
  ) {}

  public ngOnInit(): void {
    this.organizationId = this.route.snapshot.paramMap.get('id');
    this.organizationFormGroup = new FormGroup({
      _id: new FormControl('', []),
      name: new FormControl('', []),
      domain: new FormControl('', [Validators.required]),
      website: new FormControl('', []),
      description: new FormControl('', []),
      type: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      postalCode: new FormControl('', [Validators.required]),
      contactNumber: new FormControl('', [Validators.required]),
      profileImage: new FormControl(null, []),
    });
    this.oService
      .getOrganizationById(this.organizationId)
      .subscribe((res: any) => {
        this.organization = res;
        if (this.organization.type == 'rep_company') {
          this.organizationFormGroup.removeControl('city');
          this.organizationFormGroup.removeControl('address');
          this.organizationFormGroup.removeControl('postalCode');
          this.organizationFormGroup.removeControl('contactNumber');
        } else if (this.organization.type == 'hospital') {
          this.organizationFormGroup.removeControl('profileImage');
        }
        this.dataLoaded.emit();
        this.organizationFormGroup.patchValue(res);
        this.enableAutoSaving();
        this.enableStatusWatching();
      });
  }

  public enableAutoSaving() {
    this.changesSubscription = this.organizationFormGroup.valueChanges
      .pipe(
        filter(() => !this.organizationFormGroup.invalid),
        tap(() => this.isAutoSavingSubject.next(true)),
        debounceTime(1_000),
        switchMap((organization: Organization) =>
          this.oService.updateOrganization(organization).pipe(
            finalize(() => {
              this.organization = organization;
              return this.isAutoSavingSubject.next(false);
            })
          )
        )
      )
      .subscribe();
  }

  private disableAutoSaving(): void {
    this.isAutoSavingSubject.next(false);
    this.changesSubscription.unsubscribe();
  }

  public enableStatusWatching() {
    this.statusSubscription = this.organizationFormGroup.statusChanges
      .pipe(
        distinctUntilChanged(),
        pairwise(),
        tap(([previous, current]) => {
          if (previous === 'VALID' && current === 'INVALID') {
            this.disableAutoSaving();
          }
          if (current === 'VALID' && this.changesSubscription.closed) {
            this.enableAutoSaving();
            this.organizationFormGroup.updateValueAndValidity();
          }
        })
      )
      .subscribe();
  }

  public imageUploaded(document: Img) {
    this.organizationFormGroup.patchValue({
      profileImage: document,
    });
  }

  public imageRemoved() {
    this.organizationFormGroup.patchValue({
      profileImage: null,
    });
  }

  public ngOnDestroy(): void {
    this.disableAutoSaving();
    this.statusSubscription.unsubscribe();
  }
}
