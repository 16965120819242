import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/index';

@Component({
  selector: 'my-rep-organization-information',
  templateUrl: './organization-information.component.html',
  styleUrls: ['./organization-information.component.scss'],
})
export class OrganizationInformationComponent implements OnInit {
  public childDataLoaded = {
    organization: false,
    users: false,
  };
  constructor(private userService: UserService) {}

  public ngOnInit(): void {}

  public updateChildDataLoaded(child: string) {
    this.childDataLoaded[child] = true;
  }
}
