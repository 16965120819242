import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Agreement } from 'src/app/models/agreement.model';
import { AgreementService } from 'src/app/services/agreement.service';

@Component({
  selector: 'my-rep-view-agreement',
  templateUrl: './view-agreement.component.html',
  styleUrls: ['./view-agreement.component.scss'],
})
export class ViewAgreementComponent implements OnInit {
  public agreement: Agreement;
  constructor(
    private route: ActivatedRoute,
    private aService: AgreementService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.getAgreement(params.type);
    });
  }

  getAgreement(type) {
    this.aService.getAgreement(type).subscribe((res: any) => {
      this.agreement = res.data.agreement;
    });
  }
}
