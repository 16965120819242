import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'my-rep-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit {
  public icon: string;
  public title: string;
  public message: string;
  public isActionNeeded: boolean;
  public actionText: string;
  public cancelText: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ConfirmationDialogComponent,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>
  ) {}

  public ngOnInit(): void {
    this.icon = this.data.icon;
    this.title = this.data.title;
    this.message = this.data.message;
    this.isActionNeeded = this.data.isActionNeeded;
    this.actionText = this.data.actionText;
    this.cancelText = this.data.cancelText;
  }

  public onConfirm(): void {
    this.dialogRef.close(true);
  }

  public onDismiss(): void {
    this.dialogRef.close(false);
  }

  public close() {
    this.dialogRef.close();
  }
}
