import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtService } from './jwt.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private jwtService: JwtService,
    private userService: UserService,
    private router: Router
  ) {}

  login(user: any) {
    return this.http.post('/auth/signin', user);
  }

  signup(user: any) {
    return this.http.post('/auth/signup', user);
  }

  activate(user: any) {
    return this.http.post('/auth/activate', user);
  }

  resetPassword(payload: { email: string; memberId: string }) {
    return this.http.post('/auth/reset-password', payload);
  }

  changePassword(payload: { oldPassword: string; newPassword: string }) {
    return this.http.post('/auth/change-password', payload);
  }

  logout() {
    this.jwtService.destroyToken();
    this.userService.destroyUserLocal();
    this.router.navigate(['']);
  }
}
