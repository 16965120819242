import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  OrganizationService,
  SnackbarService,
  UserService,
} from 'src/app/services/index';
import { ConfirmationDialogComponent } from '../../shared/index';
import { Speciality } from 'src/app/models/speciality.model';
import { Resource } from 'src/app/models/resource.model';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
} from 'rxjs/operators';
import { ResourceService } from 'src/app/services/resource.service';
import { DocumentService } from 'src/app/services/document.service';
import { forkJoin } from 'rxjs';
import { SpecialityService } from 'src/app/services/speciality.service';
import { Organization } from 'src/app/models';

@Component({
  selector: 'my-rep-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit {
  public userFormGroup: FormGroup;
  public action: string;
  public organizationId: string;
  public specialities: Speciality[] = [];
  public isHospital: boolean;
  public hospitalFilterCtrl: FormControl = new FormControl();
  public filteredHospitals: Resource[] = [];
  public hospitalSearching: boolean = false;
  public resource: Resource;
  public organization: Organization;
  public isDataLoaded: boolean = false;
  public isLoading: boolean = false;
  public isDeleteLoading: boolean = false;
  public isFileUploading: boolean = false;
  public isFileDeleting: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<UserFormComponent>,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
    private resourceService: ResourceService,
    private docService: DocumentService,
    private organizationService: OrganizationService,
    private specialityService: SpecialityService
  ) {}

  public ngOnInit(): void {
    this.organizationId = this.data.organizationId;
    this.action = this.data.action;
    forkJoin([
      this.organizationService.getOrganizationById(this.organizationId),
      this.specialityService.getSpecialities(),
    ]).subscribe(([organization, specialities]) => {
      this.isDataLoaded = true;
      this.organization = organization;
      this.specialities = specialities;
      this.isHospital = this.organization.type == 'hospital';
      this.resource = this.organization.resource;

      this.initializeFormGroup();

      if (this.action === 'edit') {
        this.handleEditAction();
      } else {
        this.handleAddAction();
      }

      if (this.filteredHospitals.length === 0)
        this.resourceService
          .getResourcesByType('', 'hospital')
          .subscribe((res) => {
            this.updateFilteredHospitals(res);
          });

      this.initializeHospitalSearch();
    });
  }

  public initializeFormGroup() {
    this.userFormGroup = new FormGroup({
      _id: new FormControl('', []),
      memberId: new FormControl('', []),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, this.emailValidator()]),
      title: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      hospitals: new FormControl([], [Validators.required]),
      documents: new FormControl([], [this.maxDocumentsValidator(5)]),
      isPrimeAdmin: new FormControl(false, []),
      isAdmin: new FormControl(false, []),
      specialities: new FormControl(null, [Validators.required]),
    });
  }

  public initializeHospitalSearch() {
    this.hospitalFilterCtrl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter((term) => term.length > 2),
        switchMap((term) => {
          this.hospitalSearching = true;
          return this.resourceService.getResourcesByType(term, 'hospital');
        })
      )
      .subscribe((res) => {
        this.updateFilteredHospitals(res);
        this.hospitalSearching = false;
      });
  }

  public handleAddAction() {
    this.userFormGroup.controls.city.setValue(this.organization.city);
    if (this.isHospital) {
      this.filteredHospitals.push(this.resource);
      this.userFormGroup.controls.hospitals.setValue([this.resource._id]);
    }
    // if (
    //   this.userService.hasRole('superadmin') &&
    //   this.data['isPrimeAvailableForOrganization'] === false
    // ) {
    //   this.userFormGroup.controls.isPrimeAdmin.setValue(true);
    //   this.userFormGroup.controls.isAdmin.setValue(true);
    //   this.userFormGroup.controls.isPrimeAdmin.disable();
    //   this.userFormGroup.controls.isAdmin.disable();
    //   this.userFormGroup.removeControl('hospitals');
    //   this.userFormGroup.removeControl('specialities');
    //   this.userFormGroup.removeControl('documents');
    // }
  }

  public handleEditAction() {
    this.userFormGroup.patchValue(this.data.user);
    this.userFormGroup.controls.isAdmin.disable();
    this.userFormGroup.controls.email.disable();
    let hospitals = [];
    if (this.isHospital && this.data.user?.specialities?.length > 0) {
      this.userFormGroup.controls.specialities.setValue(
        this.data.user.specialities[0]
      );
    }
    if (this.data.user.hospitals) {
      for (let i = 0; i < this.data.user.hospitals.length; i++) {
        hospitals.push(this.data.user.hospitals[i]._id);
        this.filteredHospitals.push(this.data.user.hospitals[i]);
      }
    }
    this.userFormGroup.controls.hospitals.setValue(hospitals);
    if (this.data.user.isAdmin) {
      this.userFormGroup.removeControl('hospitals');
      this.userFormGroup.removeControl('specialities');
      this.userFormGroup.removeControl('documents');
    }
  }

  public maxDocumentsValidator(max: number) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value.length > max) {
        return { maxDocuments: { value: control.value } };
      }
      return null;
    };
  }

  public emailValidator() {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }
      const localPart = control.value;
      const email = `${localPart}@${this.organization.domain}`;
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(?:[a-zA-Z]{2,}|(?!))$/;
      const isValid = regex.test(email);
      return isValid
        ? null
        : { invalidCombinedEmail: { value: control.value } };
    };
  }

  public updateFilteredHospitals(hospitals: Resource[]) {
    // when populating in the beginning, we need to reverse the order to show the most relevant results first
    // hospitals.reverse();
    for (let i = 0; i < hospitals.length; i++) {
      if (
        this.filteredHospitals.findIndex(
          (hospital) => hospital._id === hospitals[i]._id
        ) === -1
      ) {
        // this.filteredHospitals.unshift(hospitals[i]);
        // push inside filteredHospitals after the index of formGroup.hospitals length
        this.filteredHospitals.splice(
          this.userFormGroup.controls?.hospitals?.value?.length || 0,
          0,
          hospitals[i]
        );
      }
    }
  }

  public submitForm() {
    if (this.userFormGroup.valid) {
      if (this.action === 'add') {
        this.addUser();
      } else if (this.action === 'edit') {
        this.updateUser();
      } else {
        this.snackbarService.openSnackBar('Invalid Action', 'Close');
      }
    }
  }

  public addUser() {
    let formValues = this.userFormGroup.getRawValue();
    let payload = {
      ...formValues,
      email: `${formValues.email}@${this.organization.domain}`,
      organization: this.organizationId,
    };
    this.isLoading = true;
    this.userService.createUser(payload, this.organizationId).subscribe(
      () => {
        this.isLoading = false;
        this.dialogRef.close();
        this.dialog.open(ConfirmationDialogComponent, {
          width: '40rem',
          autoFocus: false,
          data: {
            icon: 'user',
            title: 'User Added',
            message: 'New user details have been added successfully!',
          },
        });
      },
      (err) => {
        this.isLoading = false;
        this.snackbarService.openSnackBar(err.error.message, 'Close');
      }
    );
  }

  public updateUser() {
    let payload = {
      ...this.userFormGroup.getRawValue(),
      organization: this.organizationId,
    };
    this.isLoading = true;
    this.userService.updateUser(payload, this.organizationId).subscribe(
      (res) => {
        this.isLoading = false;
        this.dialogRef.close();
        let data = {
          icon: 'update',
          title: 'Update successful',
          message: 'Details have been updated',
        };
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '40rem',
          autoFocus: false,
          data: data,
        });
      },
      (err) => {
        this.isLoading = false;
        this.snackbarService.openSnackBar(err.error.message, 'Close');
      }
    );
  }

  public adminControlChanged($event) {
    if ($event.checked) {
      this.userFormGroup.removeControl('hospitals');
      this.userFormGroup.removeControl('specialities');
      this.userFormGroup.removeControl('documents');
    } else {
      this.userFormGroup.controls.isPrimeAdmin.setValue(false);
      this.userFormGroup.addControl(
        'hospitals',
        new FormControl([], [Validators.required])
      );
      this.userFormGroup.addControl(
        'specialities',
        new FormControl(this.isHospital ? '' : [], [Validators.required])
      );
      this.userFormGroup.addControl('documents', new FormControl([], []));
      if (this.action === 'add' && this.isHospital) {
        this.userFormGroup.controls.hospitals.setValue([this.resource._id]);
      }
    }
  }

  public handleFileInputChange(files) {
    if (this.userFormGroup.controls.documents.value.length + files.length > 5) {
      this.snackbarService.openSnackBar('Maximum 5 documents allowed', 'Close');
      return;
    }
    const params = {};
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      let fileName = files[i].name;
      let fileIndex = 1;
      while (
        this.userFormGroup.controls.documents.value.find(
          (document) => document.name === fileName
        )
      ) {
        fileName = `${files[i].name.split('.')[0]} (${fileIndex}).${
          files[i].name.split('.')[1]
        }`;
        fileIndex++;
      }
      formData.append('files', files[i], fileName);
    }
    params['formData'] = formData;
    if (this.action === 'edit') {
      params['path'] = `users/${this.data.user._id}`;
    }
    this.isFileUploading = true;
    this.docService.upload(params).subscribe(
      (res: any) => {
        let documents = this.userFormGroup.controls.documents.value;
        if (documents.length > 0) {
          documents = documents.concat(res.data.documents);
        } else {
          documents = res.data.documents;
        }
        this.userFormGroup.controls.documents.setValue(documents);
        this.isFileUploading = false;
      },
      (err) => {
        this.isFileUploading = false;
        this.snackbarService.openSnackBar(err.error.message, 'Close');
      }
    );
  }

  public removeDocument(document) {
    this.isFileDeleting = true;
    this.docService.delete(document.key).subscribe(
      (res) => {
        let documents = this.userFormGroup.controls.documents.value;
        documents = documents.filter((doc) => doc.key !== document.key);
        this.userFormGroup.controls.documents.setValue(documents);
        this.isFileDeleting = false;
      },
      (err) => {
        this.isFileDeleting = false;
        this.snackbarService.openSnackBar(err.error.message, 'Close');
      }
    );
  }

  public close() {
    this.dialogRef.close();
  }

  public openDeleteConfirmation() {
    let data = {
      icon: 'close',
      title: 'Are you sure?',
      message: `You are about to delete ${this.userFormGroup.value.firstName} ${this.userFormGroup.value.lastName}. This action cannot be undone.`,
      isActionNeeded: true,
      actionText: 'Delete',
      cancelText: 'Cancel',
    };
    const confirmationDialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      {
        width: '500px',
        autoFocus: false,
        data: data,
      }
    );
    confirmationDialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.deleteUser();
      }
    });
  }

  public deleteUser() {
    this.isDeleteLoading = true;
    this.userService
      .deleteUser(this.userFormGroup.get('_id').value, this.organizationId)
      .subscribe({
        next: (res) => {
          this.dialog.open(ConfirmationDialogComponent, {
            width: '40rem',
            data: {
              icon: 'tick',
              title: 'Delete Successful',
              message:
                'User has been deleted successfully. The process cannot be undone now.',
            },
          });
        },
        error: (err) => {
          this.snackbarService.openSnackBar(err.error.message, 'error');
        },
        complete: () => {
          this.isDeleteLoading = false;
          this.dialogRef.close();
        },
      });
  }
}
