import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { Organization, OrganizationStatus } from '../models/organization.model';
import { switchMap, take, tap } from 'rxjs/operators';
import { ViewService } from './view.service';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  private organizations: Organization[] = [];
  public organizationsChanged = new Subject<Organization[]>();
  public totalLengthChanged = new Subject<number>();

  constructor(private http: HttpClient, private viewService: ViewService) {}

  getOrganizationById(id: string): Observable<Organization> {
    return this.http.get<Organization>(`/organizations/${id}`);
  }

  getOrganizations(): Observable<Organization[]> {
    return combineLatest([
      this.viewService.orgPaginationOptions$,
      this.viewService.selectedType$,
      this.viewService.orgSearchTerm$,
    ]).pipe(
      take(1),
      switchMap(([paginationOptions, selectedType, searchTerm]) => {
        let pageIndex = paginationOptions.pageIndex;
        let pageSize = paginationOptions.pageSize;
        let url = `/organizations?page=${
          pageIndex + 1
        }&limit=${pageSize}&type=${selectedType}`;
        if (searchTerm) {
          url += `&searchTerm=${searchTerm}`;
        }
        return this.http.get<Organization[]>(url);
      }),
      tap((res: any) => {
        this.organizations = res.data.organizations;
        this.organizationsChanged.next(this.organizations.slice());
        this.totalLengthChanged.next(res.data.totalLength);
      })
    );
  }

  // createOrganization(organization: Organization): Observable<Organization> {
  //   return combineLatest([
  //     this.viewService.orgPaginationOptions$,
  //     this.viewService.selectedType$,
  //     this.viewService.orgSearchTerm$,
  //   ]).pipe(
  //     take(1),
  //     switchMap(([paginationOptions, selectedType, searchTerm]) => {
  //       let pageIndex = paginationOptions.pageIndex;
  //       let pageSize = paginationOptions.pageSize;
  //       let url = `/organizations?page=${
  //         pageIndex + 1
  //       }&limit=${pageSize}&type=${selectedType}`;
  //       if (searchTerm) {
  //         url += `&searchTerm=${searchTerm}`;
  //       }
  //       return this.http.post<Organization>(url, organization);
  //     }),
  //     tap((res: any) => {
  //       this.organizations = res.data.organizations;
  //       this.organizationsChanged.next(this.organizations.slice());
  //     })
  //   );
  // }
  createOrganization(organization: Organization) {
    return this.http.post('/organizations', organization).pipe(
      tap(() => {
        this.getOrganizations().subscribe();
      })
    );
  }

  // updateOrganization(organization: Organization): Observable<Organization> {
  //   return this.http
  //     .put<Organization>(`/organizations/${organization._id}`, organization)
  //     .pipe(
  //       tap((res: any) => {
  //         console.log('tap1');
  //         let organization = res.data.organization;
  //         const index = this.organizations.findIndex(
  //           (org) => org._id === organization._id
  //         );
  //         let { userCount, adminCount } = this.organizations[index];
  //         this.organizations[index] = organization;
  //         this.organizations[index].userCount = userCount;
  //         this.organizations[index].adminCount = adminCount;
  //         this.organizationsChanged.next(this.organizations.slice());
  //       })
  //     );
  // }

  updateOrganization(organization: Organization) {
    return this.http
      .put(`/organizations/${organization._id}`, organization)
      .pipe(
        tap(() => {
          this.getOrganizations().subscribe();
        })
      );
  }

  // updateOrganizationStatus(
  //   organizationPayload: OrganizationStatus
  // ): Observable<Organization> {
  //   return this.http
  //     .put<Organization>(
  //       `/organizations/${organizationPayload._id}/status`,
  //       organizationPayload
  //     )
  //     .pipe(
  //       tap((res: any) => {
  //         let organization = res.data.organization;
  //         const index = this.organizations.findIndex(
  //           (org) => org._id === organizationPayload._id
  //         );
  //         let { userCount, adminCount } = this.organizations[index];
  //         this.organizations[index] = organization;
  //         this.organizations[index].userCount = userCount;
  //         this.organizations[index].adminCount = adminCount;
  //         this.organizationsChanged.next(this.organizations.slice());
  //       })
  //     );
  // }

  updateOrganizationStatus(organizationPayload: OrganizationStatus) {
    return this.http
      .put(
        `/organizations/${organizationPayload._id}/status`,
        organizationPayload
      )
      .pipe(
        tap(() => {
          this.getOrganizations().subscribe();
        })
      );
  }

  goLive(organizationId: string) {
    return this.http.get(`/organizations/${organizationId}/go-live`);
  }

  // deleteOrganization(id: string): Observable<Organization> {
  //   return this.http.delete<Organization>(`/organizations/${id}`).pipe(
  //     tap((res: any) => {
  //       let organization = res.data.organization;
  //       this.organizations = this.organizations.filter(
  //         (org) => org._id !== organization._id
  //       );
  //       this.organizationsChanged.next(this.organizations.slice());
  //     })
  //   );
  // }

  deleteOrganization(id: string) {
    return this.http.delete(`/organizations/${id}`).pipe(
      tap(() => {
        this.getOrganizations().subscribe();
      })
    );
  }
}
