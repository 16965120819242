<div class="mt-2 d--f jc--c" *ngIf="isLoading">
  <mat-spinner diameter="40"></mat-spinner>
</div>
<div class="profile-container" *ngIf="!isLoading">
  <div class="d--f ai--c mb-2">
    <div class="f-700 fs-18 mr-2">My Profile</div>
    <div class="d--f ai--c" *ngIf="isAutoSaving$ | async">
      <mat-spinner class="mr-1" diameter="20"></mat-spinner>
      <span class="primary-color"> autosaving... </span>
    </div>
  </div>
  <div class="d--f ai--c profile pb-2">
    <app-profile-image-preview
      [profileImage]="user.profileImage"
      [size]="8"
    ></app-profile-image-preview>
    <div class="d--f fd--c ml-2">
      <div class="primary-color f-700 fs-20">
        {{ user.firstName }} {{ user.lastName }}
      </div>
      <div class="grey-color mt-1">Member ID#{{ user.memberId }}</div>
      <div class="primary-color f-700">
        {{ user.role.name }}
        <span *ngIf="user.isPrimeAdmin">(Prime)</span>
      </div>
    </div>
  </div>
  <form [formGroup]="profileFormGroup">
    <div class="mt-2">
      <app-image-selector
        [image]="profileFormGroup.get('profileImage')?.value"
        [path]="'users/' + user._id"
        (uploaded)="imageUploaded($event)"
        (removed)="imageRemoved()"
      ></app-image-selector>
      <div class="profile-fields-container mt-2">
        <div class="grey-color pb-2 f-700">Details</div>
        <div class="d--f">
          <div class="f--1 mr-2">
            <mat-form-field color="primary" appearance="outline">
              <mat-label>Title</mat-label>
              <input formControlName="title" matInput type="text" />
            </mat-form-field>
          </div>
          <div class="f--1">
            <mat-form-field color="primary" appearance="outline">
              <mat-label>First Name</mat-label>
              <input formControlName="firstName" matInput type="text" />
            </mat-form-field>
          </div>
        </div>
        <div class="d--f mt-2">
          <div class="f--1 mr-2">
            <mat-form-field color="primary" appearance="outline">
              <mat-label>Last Name</mat-label>
              <input formControlName="lastName" matInput type="text" />
            </mat-form-field>
          </div>
          <div class="f--1">
            <mat-form-field color="primary" appearance="outline">
              <mat-label>E-mail id</mat-label>
              <input formControlName="email" matInput type="text" readonly />
            </mat-form-field>
          </div>
        </div>
        <!-- Office Details-->
        <div class="grey-color mt-2 pb-2 f-700">Office Address</div>
        <div class="mt-2">
          <mat-form-field color="primary" appearance="outline">
            <mat-label>City</mat-label>
            <input formControlName="city" matInput type="text" />
          </mat-form-field>
        </div>
        <div class="mt-2" *hasRole="['admin', 'primeadmin']">
          <mat-form-field color="primary" appearance="outline">
            <mat-label>Hospital/Company</mat-label>
            <input matInput type="text" [value]="organizationName" readonly />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="d--f jc--fe f--g-1 mt-2">
      <button mat-raised-button color="primary" (click)="logout()">
        Logout
      </button>
    </div>
  </form>
</div>
