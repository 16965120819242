import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService, SnackbarService } from 'src/app/services';
import { CustomValidators } from 'src/app/validators/custom-validators';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  public changePasswordFormGroup: FormGroup;
  public showOldPassword: boolean = false;
  public showNewPassword: boolean = false;
  public showConfirmPassword: boolean = false;
  public isLoading: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<ChangePasswordComponent>,
    private authService: AuthService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.changePasswordFormGroup = new FormGroup({
      oldPassword: new FormControl('', [Validators.required]),
      newPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        CustomValidators.patternValidator(/\d/, { numberErr: true }),
        CustomValidators.patternValidator(/[A-Z]/, { capitalCaseErr: true }),
        CustomValidators.patternValidator(/[a-z]/, { smallCaseErr: true }),
        CustomValidators.patternValidator(
          /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
          {
            specialCharsErr: true,
          }
        ),
      ]),
      confirmPassword: new FormControl('', [Validators.required]),
    });
  }

  changePassword() {
    if (this.changePasswordFormGroup.valid) {
      if (
        this.changePasswordFormGroup.value.newPassword ===
        this.changePasswordFormGroup.value.confirmPassword
      ) {
        this.isLoading = true;
        this.authService
          .changePassword(this.changePasswordFormGroup.value)
          .subscribe(
            (res: any) => {
              this.isLoading = false;
              this.snackbarService.openSnackBar(res.message, 'Close');
              this.dialogRef.close();
            },
            (err) => {
              this.isLoading = false;
              this.snackbarService.openSnackBar(err.error.message, 'Close');
            }
          );
      } else {
        this.snackbarService.openSnackBar(
          'New password and Confirm password should be same',
          'Close'
        );
      }
    }
  }

  cancelForm() {
    this.dialogRef.close();
  }
}
