import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Agreement } from 'src/app/models/agreement.model';
import { AgreementService } from 'src/app/services/agreement.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'my-rep-manage-agreement',
  templateUrl: './manage-agreement.component.html',
  styleUrls: ['./manage-agreement.component.scss'],
})
export class ManageAgreementComponent implements OnInit {
  public agreement: Agreement;

  constructor(
    private aService: AgreementService,
    private route: ActivatedRoute,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.getAgreement(params.type);
    });
  }

  getAgreement(type) {
    this.aService.getAgreement(type).subscribe((res: any) => {
      this.agreement = res.data.agreement;
    });
  }

  saveAgreement() {
    this.aService.saveAgreement(this.agreement.type, this.agreement).subscribe(
      (res: any) => {
        this.snackbarService.openSnackBar(res.message, 'Close');
      },
      (err) => {
        this.snackbarService.openSnackBar(err.error.message, 'Close');
      }
    );
  }
}
