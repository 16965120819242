<div class="organization-details-container">
  <div
    class="d--f ai--c"
    [ngClass]="
      organizationFormGroup.get('type').value === 'hospital' ? 'mb-4' : 'mb-2'
    "
  >
    <div class="f-700 fs-22 mr-2">{{ organization?.name | titlecase }}</div>
    <div class="d--f ai--c" *ngIf="isAutoSaving$ | async">
      <mat-spinner class="mr-1" diameter="20"></mat-spinner>
      <span class="primary-color"> autosaving... </span>
    </div>
  </div>
  <form [formGroup]="organizationFormGroup">
    <div
      class="mt-2 mb-2"
      *ngIf="organizationFormGroup.get('type').value === 'rep_company'"
    >
      <app-image-selector
        [image]="organizationFormGroup.get('profileImage')?.value"
        [path]="'organizations/' + organizationId"
        (uploaded)="imageUploaded($event)"
        (removed)="imageRemoved()"
      ></app-image-selector>
    </div>
    <div class="pb-1 f-700">Details</div>
    <div class="d--f mt-1">
      <div class="f--1">
        <mat-form-field color="primary" appearance="outline">
          <mat-label>Hospital/Company Name *</mat-label>
          <input formControlName="name" matInput type="text" readonly />
        </mat-form-field>
      </div>
    </div>
    <div class="d--f mt-2">
      <div class="f--1 mr-5">
        <mat-form-field color="primary" appearance="outline">
          <mat-label>Domain *</mat-label>
          <input formControlName="domain" matInput type="text" />
        </mat-form-field>
      </div>
      <div class="f--1">
        <mat-form-field color="primary" appearance="outline">
          <mat-label>Website</mat-label>
          <input formControlName="website" matInput type="text" />
        </mat-form-field>
      </div>
    </div>
    <div class="d--f mt-2">
      <div class="f--1">
        <mat-form-field color="primary" appearance="outline">
          <mat-label>Description</mat-label>
          <input formControlName="description" matInput type="text" />
        </mat-form-field>
      </div>
    </div>
    <!-- Office Details-->
    <div *ngIf="organizationFormGroup.get('type').value === 'hospital'">
      <div class="pb-1 f-700 mt-2">Office Address</div>
      <div class="d--f mt-1">
        <div class="mr-5 f--1">
          <mat-form-field color="primary" appearance="outline">
            <mat-label>City *</mat-label>
            <input formControlName="city" matInput type="text" />
          </mat-form-field>
        </div>
        <div class="f--1">
          <mat-form-field color="primary" appearance="outline">
            <mat-label>Address *</mat-label>
            <input formControlName="address" matInput type="text" />
          </mat-form-field>
        </div>
      </div>
      <div class="d--f mt-2">
        <div class="f--1 mr-5">
          <mat-form-field color="primary" appearance="outline">
            <mat-label>Postal Code *</mat-label>
            <input formControlName="postalCode" matInput type="text" />
          </mat-form-field>
        </div>
        <div class="f--1">
          <mat-form-field color="primary" appearance="outline">
            <mat-label>Contact Number *</mat-label>
            <input
              mask="(000) 000-0000"
              formControlName="contactNumber"
              matInput
              type="text"
            />
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</div>
