import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './angular-material.module';
import {
  ConfirmationDialogComponent,
  EmptyScreenComponent,
  LoginComponent,
  ManageAgreementComponent,
  OrganizationCardComponent,
  OrganizationDetailsComponent,
  OrganizationFormComponent,
  OrganizationManagementComponent,
  PasswordValidationComponent,
  ProfileComponent,
  ProfileImagePreviewComponent,
  ImageSelectorComponent,
  SignupComponent,
  SignupDetailsComponent,
  UserFormComponent,
  UserManagementComponent,
  ViewAgreementComponent,
  ResetPasswordComponent,
  ChangePasswordComponent,
  ToggleComponent,
  OnboardingStartComponent,
  OnboardingDetailsComponent,
} from './components/index';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiInterceptor } from './interceptors/api.interceptor';
import {
  AuthComponent,
  HomeComponent,
  OrganizationInformationComponent,
  OnboardingComponent,
} from './pages/index';
import { QuillModule } from 'ngx-quill';
import { NgxMaskModule } from 'ngx-mask';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { HasRoleDirective } from './directives/has-role.directive';
import { ImportFormComponent } from './components/users/import-form/import-form.component';
import { ImageCropComponent } from './shared/components/image-crop/image-crop.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { OrganizationFormOnboardComponent } from './components/onboarding/organization-form-onboard/organization-form-onboard.component';
import { UserFormOnboardComponent } from './components/onboarding/user-form-onboard/user-form-onboard.component';
import { OnboardingAcknowledgementComponent } from './components/onboarding/onboarding-acknowledgement/onboarding-acknowledgement.component';

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    ConfirmationDialogComponent,
    EmptyScreenComponent,
    HomeComponent,
    LoginComponent,
    ManageAgreementComponent,
    OrganizationFormComponent,
    OrganizationDetailsComponent,
    OrganizationManagementComponent,
    ProfileComponent,
    ProfileImagePreviewComponent,
    ImageSelectorComponent,
    PasswordValidationComponent,
    SignupComponent,
    SignupDetailsComponent,
    UserManagementComponent,
    UserFormComponent,
    ViewAgreementComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    ToggleComponent,
    OrganizationCardComponent,
    OrganizationInformationComponent,
    UnauthorizedComponent,
    HasRoleDirective,
    ImportFormComponent,
    ImageCropComponent,
    OnboardingComponent,
    OnboardingStartComponent,
    OnboardingDetailsComponent,
    OrganizationFormOnboardComponent,
    UserFormOnboardComponent,
    OnboardingAcknowledgementComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ImageCropperModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    NgxMaskModule.forRoot(),
    QuillModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
