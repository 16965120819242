import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Organization } from 'src/app/models';
import { OrganizationService, UserService } from 'src/app/services';
import { ConfirmationDialogComponent } from '../../shared';

@Component({
  selector: 'my-rep-organization-card',
  templateUrl: './organization-card.component.html',
  styleUrls: ['./organization-card.component.scss'],
})
export class OrganizationCardComponent implements OnInit {
  @Input() organization: any;
  @Input() pinkBg: boolean;
  public isStatusUpdating: boolean = false;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private oService: OrganizationService
  ) {}

  ngOnInit(): void {}

  public openOrganizationInfo(id) {
    this.router.navigate(['/home/organization-info', id]);
  }

  public updateStatus($event, organization: Organization) {
    let payload = {
      _id: organization._id,
      isActive: $event,
    };
    this.isStatusUpdating = true;
    this.oService.updateOrganizationStatus(payload).subscribe(
      () => {
        let data = {};
        if (!$event) {
          data = {
            icon: 'organization',
            title: 'Organization Disabled',
            message:
              'All the users of this organization will be disabled and logged out.',
            isActionNeeded: false,
          };
        } else {
          data = {
            icon: 'organization',
            title: 'Organization Enabled',
            message:
              'All the users of this organization will be enabled and should be able to login.',
            isActionNeeded: false,
          };
        }
        this.dialog.open(ConfirmationDialogComponent, {
          width: '40rem',
          autoFocus: false,
          data: data,
        });
        // TODO: Don't use setTimeout
        setTimeout(() => {
          this.isStatusUpdating = false;
        }, 1000);
      },
      (err) => {
        this.isStatusUpdating = false;
      }
    );
  }

  public goLive($event) {
    $event.stopPropagation();
    this.oService.goLive(this.organization._id).subscribe(() => {
      let data = {
        icon: 'organization',
        title: 'Organization is Live',
        message:
          'All the registered users of this organization will receive an invitation email to join the platform.',
        isActionNeeded: false,
      };
      this.dialog.open(ConfirmationDialogComponent, {
        width: '40rem',
        autoFocus: false,
        data: data,
      });
    });
  }
}
