<div *ngIf="isDataLoaded">
  <form [formGroup]="form">
    <div class="section-title">Details</div>
    <div class="flex-container">
      <mat-form-field color="primary" appearance="outline">
        <mat-label>Title *</mat-label>
        <input formControlName="title" matInput type="text" />
      </mat-form-field>
      <mat-form-field color="primary" appearance="outline">
        <mat-label>First Name *</mat-label>
        <input formControlName="firstName" matInput type="text" />
      </mat-form-field>
    </div>
    <div class="flex-container">
      <mat-form-field color="primary" appearance="outline">
        <mat-label>Last Name *</mat-label>
        <input formControlName="lastName" matInput type="text" />
      </mat-form-field>
      <mat-form-field color="primary" appearance="outline">
        <mat-label>E-mail id *</mat-label>
        <div class="email-input-container">
          <input formControlName="email" matInput type="text" />
          <span class="email-domain"> @{{ organization.domain }} </span>
        </div>
      </mat-form-field>
    </div>
    <div class="flex-container">
      <mat-form-field appearance="outline">
        <mat-label>Hospitals *</mat-label>
        <mat-select formControlName="hospitals" multiple>
          <mat-option>
            <ngx-mat-select-search
              [formControl]="hospitalFilterCtrl"
              [placeholderLabel]="'Search'"
              [noEntriesFoundLabel]="null"
              [searching]="hospitalSearching"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let hospital of filteredHospitals"
            [value]="hospital._id"
            [disabled]="isHospital && resource && resource._id === hospital._id"
          >
            <div>
              {{ hospital.name | titlecase }}
              <span class="grey-color fs-12">
                ({{ hospital.city | titlecase }})
              </span>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Specialities *</mat-label>
        <mat-select formControlName="specialities" [multiple]="!isHospital">
          <mat-option
            *ngFor="let speciality of specialities"
            [value]="speciality._id"
            >{{ speciality.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <mat-form-field
      color="primary"
      class="full-width-field"
      appearance="outline"
    >
      <mat-label>City *</mat-label>
      <input formControlName="city" matInput type="text" />
    </mat-form-field>
  </form>
</div>
<div *ngIf="!isDataLoaded" style="height: 46.5rem">
  <div class="d--f jc--c ai--c h--100">
    <mat-spinner color="primary" diameter="50"></mat-spinner>
  </div>
</div>
