<div
  [hidden]="!(childDataLoaded.organization && childDataLoaded.users)"
  style="width: 70%"
>
  <div class="mb-4">
    <my-rep-organization-details
      (dataLoaded)="updateChildDataLoaded('organization')"
    ></my-rep-organization-details>
  </div>
  <div>
    <my-rep-user-management
      (dataLoaded)="updateChildDataLoaded('users')"
    ></my-rep-user-management>
  </div>
</div>
<div
  class="d--f jc--c mt-4"
  *ngIf="!(childDataLoaded.organization && childDataLoaded.users)"
>
  <mat-spinner color="primary" diameter="50"></mat-spinner>
</div>
