import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'my-rep-password-validation',
  templateUrl: './password-validation.component.html',
  styleUrls: ['./password-validation.component.scss'],
})
export class PasswordValidationComponent implements OnInit {
  @Input() passwordControl: any;
  @Input() isChangePassword: boolean = false;

  constructor() {}

  ngOnInit(): void {
    console.log(this.passwordControl);
  }
}
