import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  private userTypes: any = [
    {
      key: 'user-hcp',
      name: 'Health Care Professional',
      imageUrl: 'assets/images/hcp.svg',
      description:
        'If you are a healthcare professional, you can register as a HCP',
      stepLength: 2,
    },
    {
      key: 'user-rep',
      name: 'Medical Representative',
      imageUrl: 'assets/images/rep.svg',
      description:
        'If you are a medical representative, you can register as a Rep',
      stepLength: 3,
    },
  ];
  private userType: string;
  private organization = new BehaviorSubject<any>(null);
  private user: any;

  constructor() {}

  public get getUserType(): string {
    return this.userType;
  }

  public set setUserType(userType: string) {
    this.userType = userType;
  }

  public get getUserTypes(): any {
    return this.userTypes;
  }

  public getOrgType(): string {
    return this.userType === 'user-hcp' ? 'hospital' : 'rep_company';
  }

  public getStepLength(): number {
    return this.userTypes.find((userType) => userType.key === this.userType)
      .stepLength;
  }

  public getOrganization$(): Observable<any> {
    return this.organization.asObservable();
  }

  public setOrganization(organization: any) {
    this.organization.next(organization);
  }

  public get getUser(): any {
    return this.user;
  }

  public set setUser(user: any) {
    this.user = user;
  }
}
