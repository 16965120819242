import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Img } from 'src/app/models';
import { SnackbarService } from 'src/app/services';
import { DocumentService } from 'src/app/services/document.service';
import { ImageCropComponent } from 'src/app/shared/components/image-crop/image-crop.component';

@Component({
  selector: 'app-image-selector',
  templateUrl: './image-selector.component.html',
  styleUrls: ['./image-selector.component.scss'],
})
export class ImageSelectorComponent implements OnInit {
  @Input() image: Img;
  @Input() title: string = null;
  @Input() path: string = null;
  @Output() uploaded = new EventEmitter<Img>();
  @Output() removed = new EventEmitter<null>();
  public isFileUploading: boolean = false;
  public isFileDeleting: boolean = false;

  constructor(
    private docService: DocumentService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  public fileChangeEvent(event: any): void {
    this.dialog
      .open(ImageCropComponent, {
        data: {
          imageChangedEvent: event,
          originalFileName: event.target.files[0].name,
        },
        width: '40rem',
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.handleFileInputChange(res);
        }
      });
  }

  public handleFileInputChange(res) {
    const params = {};
    const formData = new FormData();
    formData.append('files', res.blob, res.originalFileName);
    params['formData'] = formData;
    if (this.path) {
      params['path'] = this.path;
    }
    this.isFileUploading = true;
    this.docService.upload(params).subscribe(
      (res: any) => {
        this.uploaded.emit(res.data.documents[0]);
        this.isFileUploading = false;
      },
      (err) => {
        this.isFileUploading = false;
        this.snackbarService.openSnackBar(err.error.message, 'Close');
      }
    );
  }

  public removeDocument() {
    this.isFileDeleting = true;
    let document = this.image;
    if (!document.key) {
      this.removed.emit();
      this.isFileDeleting = false;
      return;
    }
    this.docService.delete(document.key).subscribe(
      (res) => {
        this.removed.emit();
        this.isFileDeleting = false;
      },
      (err) => {
        this.isFileDeleting = false;
      }
    );
  }
}
