import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'my-rep-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
  public isSignInPage: boolean;

  constructor(private router: Router, private dialog: MatDialog) {}

  public ngOnInit(): void {}

  public goToResetScreen() {
    this.router.navigate(['/reset-password']);
  }

  public hasRoute(route: string) {
    return this.router.url.includes(route);
  }

  public navigateSignIn() {
    if (this.hasRoute('reset-password')) {
      this.router.navigate(['/login']);
    }
  }
}
