import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ViewService {
  // organization management params
  private isGridViewSubject = new BehaviorSubject<boolean>(true);
  isGridView$ = this.isGridViewSubject.asObservable();

  private selectedTypeSubject = new BehaviorSubject<string>('hospital');
  selectedType$ = this.selectedTypeSubject.asObservable();

  private orgSearchTermSubject = new BehaviorSubject<string>('');
  orgSearchTerm$ = this.orgSearchTermSubject.asObservable();

  private orgPaginationOptionsSubject = new BehaviorSubject<{
    pageSize: number;
    pageIndex: number;
  }>({
    pageSize: 6,
    pageIndex: 0,
  });
  orgPaginationOptions$ = this.orgPaginationOptionsSubject.asObservable();

  // user management params
  private isActiveTabSelectedSubject = new BehaviorSubject<boolean>(true);
  isActiveTabSelected$ = this.isActiveTabSelectedSubject.asObservable();

  private userSearchTermSubject = new BehaviorSubject<string>('');
  userSearchTerm$ = this.userSearchTermSubject.asObservable();

  private userPaginationOptionsSubject = new BehaviorSubject<{
    pageSize: number;
    pageIndex: number;
  }>({
    pageSize: 5,
    pageIndex: 0,
  });
  userPaginationOptions$ = this.userPaginationOptionsSubject.asObservable();

  constructor() {}

  toggleView() {
    this.isGridView$.pipe(take(1)).subscribe((isGridView) => {
      this.isGridViewSubject.next(!isGridView);
    });
  }

  setSelectedType(type: string) {
    this.selectedTypeSubject.next(type);
  }

  setOrgSearchTerm(term: string) {
    this.orgSearchTermSubject.next(term);
  }

  setOrgPaginationOptions(options: { pageSize: number; pageIndex: number }) {
    this.orgPaginationOptionsSubject.next(options);
  }

  setIsActiveTabSelected(isActiveTabSelected: boolean) {
    this.isActiveTabSelectedSubject.next(isActiveTabSelected);
  }

  setUserSearchTerm(term: string) {
    this.userSearchTermSubject.next(term);
  }

  setUserPaginationOptions(options: { pageSize: number; pageIndex: number }) {
    this.userPaginationOptionsSubject.next(options);
  }

  resetOrgPaginationOptions() {
    this.orgPaginationOptionsSubject.next({
      pageSize: 6,
      pageIndex: 0,
    });
  }

  resetUserPaginationOptions() {
    this.userPaginationOptionsSubject.next({
      pageSize: 5,
      pageIndex: 0,
    });
  }
}
