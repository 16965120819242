<label
  class="switch"
  [ngClass]="{
    'switch-checked': toggleChecked,
    'switch-disabled': toggleDisabled
  }"
>
  <input
    [ngModel]="toggleChecked"
    (ngModelChange)="change($event)"
    [disabled]="toggleDisabled"
    type="checkbox"
  />
  <div></div>
</label>
