import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UserService } from 'src/app/services/user.service';
import { ViewService } from 'src/app/services/view.service';
import { ConfirmationDialogComponent } from '../../shared';
import { ImportFormComponent } from '../import-form/import-form.component';
import { UserFormComponent } from '../user-form/user-form.component';

@Component({
  selector: 'my-rep-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent implements OnInit {
  @Output() dataLoaded = new EventEmitter();
  public userTableCols: string[] = ['name', 'status'];
  public users: any;
  public dataSource = new MatTableDataSource<any>([]);
  public isLoading: boolean = false;
  public organizationId: string;
  public emptyScreenOptions = {
    title: 'No Users',
    imagePath: 'assets/images/user-empty-screen.svg',
  };
  public isStatusUpdating: boolean = false;
  public toggleIndexId: string;
  public pageSizeOptions: number[] = [5, 10, 25, 50];
  public totalLength: number;
  public searchTerm: string;
  private isPrimeAvailableForOrganization: boolean = false;
  private searchSubject = new Subject<string>();

  constructor(
    private dialog: MatDialog,
    private userService: UserService,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    public viewService: ViewService
  ) {}

  ngOnInit() {
    this.organizationId = this.route.snapshot.paramMap.get('id');
    this.viewService.resetUserPaginationOptions();
    this.getUsersByOrganizationId();
    this.initializeSearch();
    this.userService.usersChanged.subscribe({
      next: (res) => {
        this.users = res;
        if (this.userService.hasRole('superadmin')) {
          const primeUsers = this.users.filter(
            (user) =>
              user.role.key === 'admin' && user.isPrimeAdmin && user.isActive
          );
          this.isPrimeAvailableForOrganization =
            primeUsers.length > 0 ? true : false;
        }
        this.dataSource.data = this.users;
        this.isLoading = false;
        this.dataLoaded.emit();
      },
      error: (err) => {
        this.snackbarService.openSnackBar(err.error.message, 'error');
        this.isLoading = false;
      },
    });
    this.userService.totalLengthChanged.subscribe({
      next: (res) => {
        this.totalLength = res;
      },
    });
  }

  public getUsersByOrganizationId() {
    this.isLoading = true;
    this.userService.getUsersByOrganizationId(this.organizationId).subscribe();
  }

  public initializeSearch() {
    this.searchSubject
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.viewService.resetUserPaginationOptions();
        this.getUsersByOrganizationId();
      });
  }

  public onPageChange(event: PageEvent) {
    this.viewService.setUserPaginationOptions({
      pageSize: event.pageSize,
      pageIndex: event.pageIndex,
    });
    this.getUsersByOrganizationId();
  }

  public onSearchChange() {
    this.viewService.setUserSearchTerm(this.searchTerm);
    this.searchSubject.next(this.searchTerm);
  }

  openUserForm(type, user?) {
    let data = {
      action: type,
      organizationId: this.organizationId,
    };
    if (this.userService.hasRole('superadmin')) {
      data['isPrimeAvailableForOrganization'] =
        this.isPrimeAvailableForOrganization;
    }
    if (type === 'edit') {
      user.isAdmin = user.role.key === 'admin' ? true : false;
      data['user'] = user;
    }
    this.dialog.open(UserFormComponent, {
      width: '720px',
      autoFocus: false,
      data: data,
    });
  }

  openImportForm() {
    const dialogRef = this.dialog.open(ImportFormComponent, {
      width: '720px',
      autoFocus: false,
      data: {
        organizationId: this.organizationId,
      },
    });
    dialogRef.afterClosed().subscribe((data) => {
      this.getUsersByOrganizationId();
    });
  }

  updateStatusFilter(status: boolean) {
    this.viewService.setIsActiveTabSelected(status);
    this.viewService.resetUserPaginationOptions();
    this.getUsersByOrganizationId();
  }

  updateStatus($event, user) {
    let payload = {
      _id: user._id,
      isActive: $event,
    };
    this.toggleIndexId = user._id;
    this.isStatusUpdating = true;
    this.userService.updateUser(payload, this.organizationId).subscribe(
      () => {
        this.dialog.open(ConfirmationDialogComponent, {
          width: '40rem',
          autoFocus: false,
          data: {
            icon: $event ? 'user' : 'user_disabled',
            title: `User ${$event ? 'activated' : 'disabled'} and moved to ${
              $event ? 'activated' : 'disabled'
            } list.`,
          },
        });
        this.isStatusUpdating = false;
      },
      () => {
        this.isStatusUpdating = false;
      }
    );
  }

  public trackByFn(index, user) {
    return user._id;
  }
}
