<div class="p-1 change-password-container">
  <div class="d--f jc--sb">
    <div class="grey-color f-700 fs-22">Change Password</div>
    <div>
      <mat-icon color="primary" class="pointer f-700" (click)="cancelForm()"
        >close</mat-icon
      >
    </div>
  </div>
  <form
    class="mt-3"
    [formGroup]="changePasswordFormGroup"
    (ngSubmit)="!isLoading && changePassword()"
  >
    <div>
      <mat-form-field color="primary" appearance="outline">
        <mat-label>Old Password</mat-label>
        <input
          formControlName="oldPassword"
          matInput
          placeholder="Enter Old Password"
          [type]="showOldPassword ? 'text' : 'password'"
        />
        <mat-icon
          matSuffix
          color="primary"
          class="pointer"
          (click)="showOldPassword = !showOldPassword"
          >{{ showOldPassword ? "visibility" : "visibility_off" }}</mat-icon
        >
      </mat-form-field>
    </div>
    <div class="mt-2">
      <mat-form-field color="primary" appearance="outline">
        <mat-label>New Password</mat-label>
        <input
          formControlName="newPassword"
          matInput
          placeholder="Enter New Password"
          [type]="showNewPassword ? 'text' : 'password'"
        />
        <mat-icon
          matSuffix
          color="primary"
          class="pointer"
          (click)="showNewPassword = !showNewPassword"
          >{{ showNewPassword ? "visibility" : "visibility_off" }}</mat-icon
        >
      </mat-form-field>
    </div>
    <div class="mt-2">
      <my-rep-password-validation
        [passwordControl]="changePasswordFormGroup.controls['newPassword']"
        [isChangePassword]="true"
      ></my-rep-password-validation>
    </div>
    <div class="mt-2">
      <mat-form-field color="primary" appearance="outline">
        <mat-label>Re-Enter New Password</mat-label>
        <input
          formControlName="confirmPassword"
          matInput
          placeholder="Re-Enter New Password"
          [type]="showConfirmPassword ? 'text' : 'password'"
        />
        <mat-icon
          matSuffix
          color="primary"
          class="pointer"
          (click)="showConfirmPassword = !showConfirmPassword"
          >{{ showConfirmPassword ? "visibility" : "visibility_off" }}</mat-icon
        >
      </mat-form-field>
    </div>
    <div class="mt-2 d--f jc--fe">
      <button
        color="primary"
        mat-raised-button
        type="submit"
        [style.opacity]="isLoading ? 0.5 : 1"
      >
        Change Password
        <mat-icon class="ml-2" *ngIf="isLoading">
          <mat-spinner color="accent" diameter="20"> </mat-spinner>
        </mat-icon>
      </button>
    </div>
  </form>
</div>
