import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService, SnackbarService } from 'src/app/services';
import { ConfirmationDialogComponent } from '../../shared/index';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  public resetPasswordFormGroup: FormGroup;
  public isLoading: boolean = false;
  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private snackbarService: SnackbarService,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.resetPasswordFormGroup = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      memberId: new FormControl('', [Validators.required]),
    });
  }

  public retrievePassword() {
    if (this.resetPasswordFormGroup.valid) {
      this.isLoading = true;
      this.authService
        .resetPassword(this.resetPasswordFormGroup.value)
        .subscribe(
          (res) => {
            this.isLoading = false;
            let data = {
              title: 'Check your mail',
              message: 'We have sent a new password to your registered e-mail.',
              isActionNeeded: true,
              actionText: 'Open e-mail',
              cancelText: 'Ok',
            };
            const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
              width: '40rem',
              autoFocus: false,
              data: data,
            });
            dialogRef.afterClosed().subscribe((result) => {
              if (result) {
                window.open('https://mail.google.com', '_blank');
              }
              if (result === false) {
                this.router.navigate(['/login']);
              }
            });
          },
          (err) => {
            this.isLoading = false;
            this.snackbarService.openSnackBar(err.error.message, 'Close');
          }
        );
    }
  }
}
