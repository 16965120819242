<div class="d--f jc--c mb-2">
  <h2>{{ agreement.name }}</h2>
</div>
<div class="ml-2 mr-2">
  <quill-editor
    [(ngModel)]="agreement.content"
    format="html"
    scrollingContainer="html"
    placeholder="Please add content here..."
    [styles]="{ height: '60rem' }"
  ></quill-editor>
</div>
<div class="d--f jc--c mt-2">
  <button
    mat-raised-button
    color="primary"
    (click)="saveAgreement()"
    [disabled]="!agreement.content"
  >
    Save
  </button>
</div>
