import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { JwtService } from '../services/jwt.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private jwtService: JwtService) {}
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    request = request.clone({
      url: `${environment.baseApiUrl}${request.url}`,
    });
    const headersConfig = {
      // 'Content-Type': 'application/json',
      // Accept: 'application/json',
    };
    const token = this.jwtService.getToken();
    if (token) {
      headersConfig['Authorization'] = `Bearer ${token}`;
    }
    request = request.clone({ setHeaders: headersConfig });
    return next.handle(request);
  }
}
