<div class="login-container">
  <div class="fs-28 f-900 mb-2 l-spacing">Sign In</div>
  <form
    [formGroup]="loginFormGroup"
    (ngSubmit)="!isLoading && login()"
    novalidate
  >
    <div>
      <mat-form-field color="primary" appearance="outline">
        <mat-label>Member ID</mat-label>
        <input
          type="number"
          formControlName="memberId"
          matInput
          placeholder="Enter Member ID"
        />
      </mat-form-field>
    </div>
    <div class="mt-2">
      <mat-form-field color="primary" appearance="outline">
        <mat-label>Password</mat-label>
        <input
          formControlName="password"
          matInput
          placeholder="Enter Password"
          [type]="showPassword ? 'text' : 'password'"
        />
        <mat-icon
          matSuffix
          color="primary"
          class="pointer"
          (click)="toggleVisibility()"
          >{{ showPassword ? "visibility" : "visibility_off" }}</mat-icon
        >
      </mat-form-field>
    </div>
    <div class="mt-2 d--f jc--sb">
      <button
        [routerLink]="'/signup'"
        color="accent"
        class="grey-color"
        mat-raised-button
        type="button"
      >
        Sign Up
      </button>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [style.opacity]="isLoading ? 0.5 : 1"
      >
        Sign In
        <mat-icon class="ml-2" *ngIf="isLoading">
          <mat-spinner color="accent" diameter="20"> </mat-spinner>
        </mat-icon>
      </button>
    </div>
  </form>
</div>
