import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AuthService,
  OrganizationService,
  UserService,
} from 'src/app/services/index';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  pairwise,
  switchMap,
  tap,
} from 'rxjs/operators';
import { Img } from 'src/app/models';

@Component({
  selector: 'my-rep-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  public user: any;
  private changesSubscription: Subscription;
  private statusSubscription: Subscription;
  private isAutoSavingSubject = new BehaviorSubject<boolean>(false);
  public isAutoSaving$ = this.isAutoSavingSubject.asObservable();
  public organizationName: string = '';
  public isLoading: boolean = false;
  public isFileUploading: boolean = false;
  public isFileDeleting: boolean = false;

  /**
   * Form group of the component
   */
  public profileFormGroup: FormGroup;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private organizationService: OrganizationService
  ) {}

  public ngOnInit(): void {
    this.user = this.userService.getUserLocal();
    if (this.userService.hasRole(['admin', 'primeadmin'])) {
      this.isLoading = true;
      this.organizationService
        .getOrganizationById(this.user.organization)
        .subscribe(
          (organization) => {
            this.isLoading = false;
            this.organizationName = organization.name;
          },
          (error) => {
            this.isLoading = false;
            console.log(error);
          }
        );
    }
    this.profileFormGroup = new FormGroup({
      _id: new FormControl(this.user._id, []),
      title: new FormControl(this.user.title, [Validators.required]),
      firstName: new FormControl(this.user.firstName, [Validators.required]),
      lastName: new FormControl(this.user.lastName, [Validators.required]),
      email: new FormControl(this.user.email, [
        Validators.required,
        Validators.email,
      ]),
      city: new FormControl(this.user.city, [Validators.required]),
      profileImage: new FormControl(this.user.profileImage, []),
    });

    this.enableAutoSaving();
    this.enableStatusWatching();
  }

  public enableAutoSaving() {
    this.changesSubscription = this.profileFormGroup.valueChanges
      .pipe(
        filter(() => !this.profileFormGroup.invalid),
        tap(() => this.isAutoSavingSubject.next(true)),
        debounceTime(1_000),
        switchMap((userDetails) =>
          this.userService.updateUser(userDetails).pipe(
            finalize(() => {
              this.userService.setUserDetailsLocal(userDetails);
              this.user = this.userService.getUserLocal();
              return this.isAutoSavingSubject.next(false);
            })
          )
        )
      )
      .subscribe();
  }

  private disableAutoSaving(): void {
    this.isAutoSavingSubject.next(false);
    this.changesSubscription.unsubscribe();
  }

  public enableStatusWatching() {
    this.statusSubscription = this.profileFormGroup.statusChanges
      .pipe(
        distinctUntilChanged(),
        pairwise(),
        tap(([previous, current]) => {
          if (previous === 'VALID' && current === 'INVALID') {
            this.disableAutoSaving();
          }
          if (current === 'VALID' && this.changesSubscription.closed) {
            this.enableAutoSaving();
            this.profileFormGroup.updateValueAndValidity();
          }
        })
      )
      .subscribe();
  }

  public imageUploaded(document: Img) {
    this.profileFormGroup.patchValue({
      profileImage: document,
    });
  }

  public imageRemoved() {
    this.profileFormGroup.patchValue({
      profileImage: null,
    });
  }

  public logout() {
    this.authService.logout();
  }

  public ngOnDestroy(): void {
    this.disableAutoSaving();
    this.statusSubscription.unsubscribe();
  }
}
