import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Agreement } from '../models/agreement.model';

@Injectable({
  providedIn: 'root',
})
export class AgreementService {
  constructor(private http: HttpClient) {}

  getAgreement(type: string) {
    return this.http.get(`/agreements/${type}`);
  }

  saveAgreement(type: string, agreement: Agreement) {
    return this.http.put(`/agreements/${type}`, agreement);
  }
}
