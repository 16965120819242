import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserService } from '../services';

@Directive({
  selector: '[hasRole]',
})
export class HasRoleDirective {
  @Input() hasRole: string | string[];
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private userService: UserService
  ) {}

  ngOnInit() {
    if (this.userService.hasRole(this.hasRole)) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainerRef.clear();
    }
  }
}
