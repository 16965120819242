import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
  take,
} from 'rxjs/operators';
import { Resource } from 'src/app/models/resource.model';
import { ResourceService } from 'src/app/services/resource.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ConfirmationDialogComponent } from '../../shared/index';
import { Img } from 'src/app/models';
import { ViewService } from 'src/app/services/view.service';

@Component({
  selector: 'my-rep-organization-form',
  templateUrl: './organization-form.component.html',
  styleUrls: ['./organization-form.component.scss'],
})
export class OrganizationFormComponent implements OnInit {
  public organizationFormGroup: FormGroup;
  public isLoading: boolean = false;
  public nameFilterCtrl: FormControl = new FormControl();
  public filteredResources: Resource[] = [];
  public filteredHospitals: Resource[] = [];
  public filteredDeviceCompanies: Resource[] = [];
  public resourceSearching: boolean = false;
  public isFileUploading: boolean = false;
  public isFileDeleting: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<OrganizationFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackbarService: SnackbarService,
    private oService: OrganizationService,
    private resourceService: ResourceService,
    private dialog: MatDialog,
    private viewService: ViewService
  ) {}

  public ngOnInit(): void {
    this.initializeFormGroup();
    this.viewService.selectedType$.pipe(take(1)).subscribe((selectedType) => {
      this.organizationFormGroup.get('type').setValue(selectedType);
      this.typeUpdated(selectedType);
    });
    this.initializeResourceSearch();
    this.organizationFormGroup.get('type').valueChanges.subscribe((val) => {
      this.typeUpdated(val);
    });
  }

  public initializeFormGroup() {
    this.organizationFormGroup = new FormGroup({
      _id: new FormControl('', []),
      name: new FormControl('', [Validators.required]),
      domain: new FormControl('', [Validators.required]),
      website: new FormControl('', []),
      description: new FormControl('', []),
      type: new FormControl(null, [Validators.required]),
      city: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      postalCode: new FormControl('', [Validators.required]),
      contactNumber: new FormControl('', [Validators.required]),
      resource: new FormControl('', [Validators.required]),
    });
  }

  public initializeResourceSearch() {
    this.nameFilterCtrl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter((term) => term.length > 2),
        switchMap((term) => {
          this.resourceSearching = true;
          return this.resourceService.getResourcesByType(
            term,
            this.organizationFormGroup.get('type').value
          );
        })
      )
      .subscribe((res) => {
        if (this.organizationFormGroup.get('type').value === 'hospital') {
          this.updateFilteredHospitals(res);
        } else if (
          this.organizationFormGroup.get('type').value === 'rep_company'
        ) {
          this.updateFilteredDeviceCompanies(res);
        }
        this.resourceSearching = false;
      });
  }

  public typeUpdated(type: string) {
    this.organizationFormGroup.patchValue({
      name: '',
      domain: '',
      website: '',
      resource: '',
    });
    this.organizationFormGroup.get('name').markAsUntouched();
    this.organizationFormGroup.get('domain').markAsUntouched();
    if (type === 'hospital') {
      this.organizationFormGroup.addControl(
        'city',
        new FormControl('', [Validators.required])
      );
      this.organizationFormGroup.addControl(
        'address',
        new FormControl('', [Validators.required])
      );
      this.organizationFormGroup.addControl(
        'postalCode',
        new FormControl('', [Validators.required])
      );
      this.organizationFormGroup.addControl(
        'contactNumber',
        new FormControl('', [Validators.required])
      );
      this.organizationFormGroup.removeControl('profileImage');
    } else if (type === 'rep_company') {
      this.organizationFormGroup.removeControl('city');
      this.organizationFormGroup.removeControl('address');
      this.organizationFormGroup.removeControl('postalCode');
      this.organizationFormGroup.removeControl('contactNumber');
      this.organizationFormGroup.addControl(
        'profileImage',
        new FormControl(null, [])
      );
    }
  }

  public updateFilteredHospitals(resources: Resource[]) {
    // when populating in the beginning, we need to reverse the order to show the most relevant results first
    resources.reverse();
    for (let i = 0; i < resources.length; i++) {
      if (
        this.filteredHospitals.findIndex(
          (hospital) => hospital._id === resources[i]._id
        ) === -1
      ) {
        this.filteredHospitals.unshift(resources[i]);
      }
    }
  }

  public updateFilteredDeviceCompanies(resources: Resource[]) {
    // when populating in the beginning, we need to reverse the order to show the most relevant results first
    resources.reverse();
    for (let i = 0; i < resources.length; i++) {
      if (
        this.filteredDeviceCompanies.findIndex(
          (deviceCompany) => deviceCompany._id === resources[i]._id
        ) === -1
      ) {
        this.filteredDeviceCompanies.unshift(resources[i]);
      }
    }
  }

  public setDetails(event: any) {
    let resource = event.value;
    if (this.organizationFormGroup.get('type').value === 'hospital') {
      this.organizationFormGroup.patchValue({
        address: resource.address,
        city: resource.city,
        postalCode: resource.postalCode,
        contactNumber: resource.contactNumber,
        resource: resource._id,
      });
    } else if (this.organizationFormGroup.get('type').value === 'rep_company') {
      this.organizationFormGroup.patchValue({
        resource: resource._id,
      });
      if (resource.logo) {
        this.organizationFormGroup.patchValue({
          profileImage: {
            name: null,
            type: null,
            location: resource.logo,
            key: null,
          },
        });
      }
    }
  }

  public addOrganization() {
    if (this.organizationFormGroup.valid) {
      this.isLoading = true;
      let payload = this.organizationFormGroup.value;
      payload.name = this.organizationFormGroup.get('name').value.name;
      this.oService.createOrganization(payload).subscribe(
        (res: any) => {
          this.isLoading = false;
          this.dialogRef.close();
          let data = {
            icon: 'organization',
            title: 'Organization Added',
            message: 'New organization details have been added successfully!',
          };
          this.dialog.open(ConfirmationDialogComponent, {
            width: '40rem',
            autoFocus: false,
            data: data,
          });
        },
        (err) => {
          this.snackbarService.openSnackBar(err.error.message, 'Close');
          this.isLoading = false;
        }
      );
    }
  }

  public imageUploaded(document: Img) {
    this.organizationFormGroup.patchValue({
      profileImage: document,
    });
  }

  public imageRemoved() {
    this.organizationFormGroup.patchValue({
      profileImage: null,
    });
  }

  public cancelForm() {
    this.dialogRef.close();
  }
}
