import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  LoginComponent,
  ManageAgreementComponent,
  OrganizationManagementComponent,
  ProfileComponent,
  SignupComponent,
  SignupDetailsComponent,
  UserManagementComponent,
  ViewAgreementComponent,
  ResetPasswordComponent,
  OnboardingStartComponent,
  OnboardingDetailsComponent,
} from './components/index';
import { AuthRoleGuard } from './guards/index';
import {
  AuthComponent,
  HomeComponent,
  OrganizationInformationComponent,
} from './pages/index';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { OnboardingComponent } from './pages/onboarding/onboarding.component';
import { OnboardingAcknowledgementComponent } from './components/onboarding/onboarding-acknowledgement/onboarding-acknowledgement.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        component: LoginComponent,
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
      },
      {
        path: 'signup',
        component: SignupComponent,
      },
      {
        path: 'signup-details',
        canActivate: [AuthRoleGuard],
        data: { authorizedRoles: ['admin', 'primeadmin'] },
        component: SignupDetailsComponent,
      },
    ],
  },
  {
    path: 'home',
    canActivate: [AuthRoleGuard],
    data: {
      authorizedRoles: ['superadmin', 'admin', 'primeadmin'],
    },
    component: HomeComponent,
    children: [
      // Home Page for super admin
      {
        path: 'organizations',
        component: OrganizationManagementComponent,
        canActivate: [AuthRoleGuard],
        data: { authorizedRoles: ['superadmin'] },
      },
      // Home Page for prime admin and admin
      {
        path: 'organizations/:id',
        component: UserManagementComponent,
        canActivate: [AuthRoleGuard],
        data: { authorizedRoles: ['admin', 'primeadmin'] },
      },
      // organization and user details page for super admin
      {
        path: 'organization-info/:id',
        component: OrganizationInformationComponent,
        canActivate: [AuthRoleGuard],
        data: { authorizedRoles: ['superadmin'] },
      },
      {
        path: 'manage-agreement',
        component: ManageAgreementComponent,
        canActivate: [AuthRoleGuard],
        data: { authorizedRoles: ['superadmin'] },
      },
      {
        path: 'profile',
        component: ProfileComponent,
      },
    ],
  },
  {
    path: 'view-agreement',
    component: ViewAgreementComponent,
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: 'join',
    component: OnboardingComponent,
    children: [
      {
        path: '',
        component: OnboardingStartComponent,
      },
      {
        path: 'details/:userType',
        component: OnboardingDetailsComponent,
      },
      {
        path: 'acknowledgement',
        component: OnboardingAcknowledgementComponent,
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
