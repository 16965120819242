import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import jwtDecode from 'jwt-decode';
import { JwtService, UserService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class AuthRoleGuard implements CanActivate {
  constructor(
    private jwtService: JwtService,
    private userService: UserService,
    private router: Router
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let token = this.jwtService.getToken();
    if (token) {
      const decodedToken: any = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        this.jwtService.destroyToken();
        this.router.navigate(['']);
        return false;
      }
      const authorizedRoles = route.data.authorizedRoles;
      if (this.userService.hasRole(authorizedRoles)) {
        return true;
      } else {
        this.router.navigate(['/unauthorized']);
        return false;
      }
    }
    this.router.navigate(['']);
    return false;
  }
}
