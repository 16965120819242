import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
} from 'rxjs/operators';
import { Organization } from 'src/app/models';
import { Resource } from 'src/app/models/resource.model';
import { Speciality } from 'src/app/models/speciality.model';
import { OnboardingService } from 'src/app/services';
import { ResourceService } from 'src/app/services/resource.service';
import { SpecialityService } from 'src/app/services/speciality.service';

@Component({
  selector: 'my-rep-user-form-onboard',
  templateUrl: './user-form-onboard.component.html',
  styleUrls: ['./user-form-onboard.component.scss'],
})
export class UserFormOnboardComponent implements OnInit {
  @Input() form: FormGroup;
  public organization: Organization;
  public specialities: Speciality[] = [];
  public isHospital: boolean = false;
  public isDataLoaded: boolean = false;
  public resource: Resource;
  public filteredHospitals: Resource[] = [];
  public hospitalFilterCtrl: FormControl = new FormControl();
  public hospitalSearching: boolean = false;

  constructor(
    private onboardingService: OnboardingService,
    private specialityService: SpecialityService,
    private resourceService: ResourceService
  ) {}

  ngOnInit(): void {
    this.onboardingService.getOrganization$().subscribe((organization) => {
      if (!organization) {
        return;
      }
      this.organization = organization;
      this.specialityService.getSpecialities().subscribe((res) => {
        this.isDataLoaded = true;
        this.specialities = res;
        this.isHospital = this.organization.type === 'hospital';
        this.resource = this.organization.completeResource;
        this.form.controls.city.setValue(this.organization.city);
        this.filteredHospitals = [];
        if (this.isHospital) {
          this.filteredHospitals.push(this.resource);
          this.form.controls.hospitals.setValue([this.resource._id]);
        }
        if (this.filteredHospitals.length === 0)
          this.resourceService
            .getResourcesByType('', 'hospital')
            .subscribe((res) => {
              this.updateFilteredHospitals(res);
            });
        this.initializeHospitalSearch();
      });
    });
  }

  public initializeHospitalSearch() {
    this.hospitalFilterCtrl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter((term) => term.length > 2),
        switchMap((term) => {
          this.hospitalSearching = true;
          return this.resourceService.getResourcesByType(term, 'hospital');
        })
      )
      .subscribe((res) => {
        this.updateFilteredHospitals(res);
        this.hospitalSearching = false;
      });
  }

  public updateFilteredHospitals(hospitals: Resource[]) {
    // when populating in the beginning, we need to reverse the order to show the most relevant results first
    // hospitals.reverse();
    for (let i = 0; i < hospitals.length; i++) {
      if (
        this.filteredHospitals.findIndex(
          (hospital) => hospital._id === hospitals[i]._id
        ) === -1
      ) {
        this.filteredHospitals.splice(
          this.form.controls?.hospitals?.value?.length || 0,
          0,
          hospitals[i]
        );
      }
    }
  }
}
