import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Resource } from '../models/resource.model';

@Injectable({
  providedIn: 'root',
})
export class ResourceService {
  constructor(private http: HttpClient) {}

  getResourcesByType(searchTerm: string, type: string): Observable<Resource[]> {
    return this.http.get<Resource[]>(
      `/resources/getResourcesByType/${type}?searchTerm=${searchTerm}`
    );
  }
}
